import styles from './OrderPlaced.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {useParams} from 'react-router-dom';
import { useEffect,useState } from 'react';
import { GetApI } from '../ApiModules/ApiInterFace';
import OrderStatus from './OrderStatus';

import React from 'react';

const OrderPlaced = () => {
  let params = useParams();

console.log(params,"iiiiii ")
const [order, setOrder] = useState(null);
const [showStatus, setShowStatus] = useState(true);
console.log(order,"orderdata")
  function getOrders(Output){
    console.log(Output ,"xvfdbedf")
    if (Output.outcome === "success"){
    setOrder(Output.order)
    }
  };

  useEffect(() => {
     GetApI({path:"/getorders/" + params.uuid ,callbackfunc:getOrders});
  },[]);


return(
    <div>
       {order && !showStatus &&
       <div>
       <div className={styles.div0001}>
       <FontAwesomeIcon  icon={solid("circle-check")} size='3x' color='rgb(100, 200, 70)'/>
           <p>OrderPlaced Successfully</p>
           <p>{"Order ID: "+ order.uuid}</p>
       </div>
       <div className={styles.div0002}>
           <p><b>Uploaded Rx</b></p>
           <div>
               {order.details.precription.map((e,index) => 
                 <img className = {styles.choosenImg} key={index} src={e} alt ="prescription" />
               )}
           </div>
           <div className={styles.div0003}>Our Pharmacy team will verfy your request and call you to confirm medicin and quality</div>
       </div>
       <div className={styles.div1000}>
         <p className={styles.para1000}>Delivered To</p>
         <div className={styles.div1001}>
           <div className={styles.subdiv101a}>
             <p className={styles.para1001}><b>{order.consumer['firstname'] + " "+ order.consumer['lastname']}</b></p>
             <p className={styles.para0001}>{order.details.delivery_address.buldingName}</p>
             <p className={styles.para0001}>{order.details.delivery_address.street},{order.details.delivery_address.taluk}</p>
             <p className={styles.para0001}>{order.details.delivery_address.district},{order.details.delivery_address.state}</p>
             <p className={styles.para0001}>Pincode:{order.details.delivery_address.pin}</p>
           </div>
           <div className={styles.subdiv1001b}>
            <p>{"Ph :"+ order.phone}</p>
           </div>
         </div>
       </div>
       <div className={styles.div0004}>
           <div className={styles.div0005}>CANCEL ORDER</div>
           <div className={styles.div0006} onClick ={() => setShowStatus(true)}>CHECK STATUS</div>
       </div>
       </div>
       }
       {order && showStatus && <OrderStatus order={order}/>}
    </div>  
)
};

export default OrderPlaced;