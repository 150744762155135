import React from 'react';
import MainHeader from './MainHeader';
import SideBar from './SideBar/SideBar';


const Navbar = (props) =>{

    const menu = [{name:'login',link:'/login'},
               {name:'Instant Service',link:'/Instant Service'},
               {name:'Book Appoinment',link:'/Instant Service'},
               {name:'DigitalMarketing',link:'/Instant Service'},
               {name:'My mind space<',link:'/Instant Service'},
               {name:'Doctor Login',link:'/DoctorDashboard'},

              ]


    return(
            <div>
            <MainHeader iconClicked={props.close} />
            <SideBar show={props.showSideBar} close={props.close} menu={menu}/>   
            </div>
    )
}
export default Navbar;