import img1 from '../../Assets/beekeymedic.jpeg';
import Styles from './DeliveryBoysLogin.module.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React from 'react';



const DeliveryBoysLogin = () =>{
    return(
    <div className={Styles.div0001}>
        <div className={Styles.div0002}>
            {/* <div className={Styles.div0003}>
                <h2 className={Styles.h2001}>Work with Beekey medic</h2>
                <h3  className={Styles.h3001}>Register and start your <br/>service with Beekey Medic</h3> */}
                {/* <button className={Styles.button001}>sign up</button> */}
            {/* </div> */}
            <div className={Styles.div0004}>
                <img src={img1} className={Styles.beekeylogo}  alt='beekeymedicLogo'></img>
                <h3  className={Styles.h3002}>Sign in to your account</h3>
                <form className={Styles.form001}>       
                <Box
                 component="form"
                   sx={{
                   '& > :not(style)': { m: 1.5, width: '90%',},
                 }}
                  noValidate
                     autoComplete="off"
                >
                    <TextField className={Styles.txt001} id="outlined-basic" label="Email" variant="outlined" />
                    <TextField className={Styles.txt001} id="outlined-basic" label="password" variant="outlined" />
                    </Box>
                    <div>
                     <div className={Styles.div0006}>Forgot Password</div>   
                     <button className={Styles.button002}>SIGN IN</button> 
                     <button className={Styles.button001}>sign up</button>  
                    </div>
                </form>
            </div>
        </div>
    </div>)
};

export default DeliveryBoysLogin;