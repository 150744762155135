import styles from './InstaDoctor.module.css';
import beekey from '../../Assets/beekey.jpeg';
import img1 from '../../Assets/devimg.png';
import img2 from  '../../Assets/protected3.png';
import img3 from '../../Assets/girldoc.jpeg';
import BeekeyImage from '../../Assets/bm-icon-3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/styles.css';
import QuestionsAnswers from './QuestionsAnswer';
import React from 'react';
import MainHeader from '../MainHeader';
import Footer from './Footer';
import Navbar from '../../Component/Navbar';



const doctorsinfo = [
   {id:1, type:  "COVID Consultation",availableTime: "9AM -10PM",marketPrice:"600",offerdPrice:"400"},
   {id:2, type:  "Consultatnt Physician",availableTime: "9AM -11PM",marketPrice:"600",offerdPrice:"400"},
   {id:3, type:  "Gynecology",availableTime: "9AM -5PM",marketPrice:"1000",offerdPrice:"888"},
   {id:4, type:  "Pediatrics",availableTime: "9AM -8PM",marketPrice:"300",offerdPrice:"250"},
   {id:5, type:  "Dermatology",availableTime: "9AM -7PM",marketPrice:"390",offerdPrice:"190"},
   {id:6, type:  "ENT",availableTime: "9AM -9PM",marketPrice:"340",offerdPrice:"240"},
   {id:7, type:  "Nutrition Counselling",availableTime: "9AM -9PM",marketPrice:"500",offerdPrice:"250"},
   {id:8, type:  "Ortho pedics",availableTime: "9AM -6PM",marketPrice:"400",offerdPrice:"300"},
   {id:9, type:  "psycology",availableTime: "9AM -7PM",marketPrice:"700",offerdPrice:"500"},
   {id:10, type:  "pysicatry",availableTime: "9AM -8PM",marketPrice:"6000",offerdPrice:"1500"},
   {id:11, type:  "cardiology",availableTime: "9AM -9.40PM",marketPrice:"3000",offerdPrice:"1000"},
   {id:12, type:  "eurology",availableTime: "9AM -9.55PM",marketPrice:"570",offerdPrice:"500"},
]; 


const QuestionAnswers = [
   {question: "Are the specialists available 24/7?", 
    answer:"Our specialists are available for consultation from 9AM to 9PM on all the 7 days of the week"},
   {question:"It says 'All doctors are busy now'. When will I get a slot for consultation? How long should I wait before trying again?",
   answer:"ou might be getting this message because all our doctors are busy at the moment. Try starting a chat in another 10 mins"},
   {question:"What are the qualifications of the specialist?",
   answer:"All specialists on the Tata Health platform are highly qualified and experienced physicians with a minimum qualification of M.D. (Doctor of Medicine) in their chosen field of specialisation, in addition to their MBBS degree.You can see their details (Name, experience, degrees) before you make the payment for the specialist consultation"}, 
   {question:"I consulted with Dr. XYZ 5 days ago. Can I connect with him/her again for a follow up? What will be the consultation fee?",
    answer: "You are entitled to one free follow-up consultation with a specialist doctor within 7 days of your first consultation. We will connect you to the same specialist depending on slot availability."},
    {question:"Payment not reflected in TDH App? (But reflecting in my bank account)?",
    answer: "It may take a while for the payment to reflect in the app. If there is no progress, reach out to our customer care team on care@tatahealth.com."},
    {question:"Payment Failure | Repeated Payment Failure",
    answer: "Please re-initiate the payment in 10 minutes. In case of repeated failures, you can reach out to customer care and they will assist you."},
    {question:"I missed my appointment. What should I do now?",
    answer: "You must initiate another chat via Insta Doc and mention that you missed your appointment. We will help you book an appointment on the next available time slot. You will receive a coupon code which will allow you to reschedule for free."},
    {question:"Lost internet connectivity mid chat. What happens to my payment? Can I continue the chat later?",
    answer: "Yes you can continue the chat (given you regain connectivity in the appointment window, else you can initiate a new chat). We will help you connect to a specialist without any extra cost."}
] 


const InstaDoctor = () => {


return(
    <div className={styles.main3231}> 
      <Navbar/>

      <div className={styles.heading}>
         <h2>Available Doctor Categories</h2>
      </div>

      <div >
        
        
         <div  className={styles.content010} >
            {doctorsinfo.map((e) => {return(
            <div className={styles.content007} key={e.id}>
               <img  className={ e.id % 2 === 0 ?styles.img003:styles.img002} src={img3} alt="doctor"/>
               <p style={{marginLeft:"5px"}}>{e.type}</p>
               <div className={styles.content008}>
                <p className={styles.para008}>Avaliable</p>
                <p className={styles.para008}>{e.availableTime}</p>
               </div>
               <div className={styles.content009}>
                <p>{e.offerdPrice}rs</p>
                <del className={styles.del001}>{e.marketPrice}rs</del>
               </div>
               
            </div>)
            })}
         </div>
       
      </div>
      <div className={styles.middle}>
         <div className={styles.default}>
            <h1>
               Daily Wishes Display here
            </h1>

         </div>
      </div>
      <div  className={styles.content013}>
            <h2 className={styles.para007}>Frequently Asked Questions</h2>
            <QuestionsAnswers  questionsAnswer={QuestionAnswers}/>        
         </div>


        < Footer/>
    </div>
    )
};

export default InstaDoctor;