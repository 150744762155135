import React from "react";

const RazorpayButton = ({proceed}) => {
  console.log("**************amount",proceed.amount);
    const options = {
      key: proceed.keyid, // Enter the Key ID generated from the Dashboard
      amount: proceed.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: 'beekeymedics', // your business name
      description: 'Beekey Coustomer Money Transaction',
      image: 'https://w7.pngwing.com/pngs/825/525/png-transparent-logo-cross-red-hospital-medical-office-blue-logo-color-thumbnail.png',
      order_id: proceed.order_id, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: 'https://www.google.com/',
      prefill: {
        name: proceed.username, // your customer's name
        email: proceed.useremail,
        contact:proceed.userphone, // Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#3399cc',
      },
    };

  const rzp1 = new window.Razorpay(options);
  rzp1.on('payment.failed', function (response) {
    console.log(response.error.code);
    console.log(response.error.description);
    console.log(response.error.source);
    console.log(response.error.step);
    console.log(response.error.reason);
    console.log(response.error.metadata.order_id);
    console.log(response.error.metadata.payment_id);
  });

  const handleClick = (e) => {
    rzp1.open();
    e.preventDefault();
  };

  return (
    <div>
      <button id="rzp-button1" onClick={handleClick}>Pay</button>
    </div>
  );
};

export default RazorpayButton;
