import style from './DoctorRegister.module.css';
import DigitalService from '../Assets/bestProducts.jpg';
import dedication from '../Assets/dedication.jpg';
import Contact from './Offers/Contact';
import BeekeyInfo from './Offers/BeekeyInfo';
import BeekeyGenaralInfo from './Offers/BeekeyGenaralInfo';
import ContactForm from './ConatctForm ';
import MainHeader from './MainHeader';
import SideBar from './SideBar/SideBar';
import React from 'react';

const Doctors = (props) => {


const menu = [{name:'login',link:'/login'},
    {name:'Instant Service',link:'/Instant Service'},
    {name:'Book Appoinment',link:'/Instant Service'},
    {name:'DigitalMarketing',link:'/Instant Service'},
    {name:'My mind space<',link:'/Instant Service'}]

return (
    <div>
    <MainHeader iconClicked={props.close} />
    <SideBar show={props.showSideBar} close={props.close} menu={menu} />    
    <div className={style.body}>    
        <div style={{fontSize:"55px", fontWeight:"500",marginTop:"25%",marginLeft:"50%",display:"flex"
        ,paddingTop:"20%"
        ,flexDirection:"column", color:'white',textAlign:"right",paddingRight:"200px"}}>
            Become Our Premium<b><font color="red">Customer</font></b>
        <button  style={{fontSize:"20px",fontWeight:"800"
                 ,width:"150px"
                 ,height:"40px"
                 ,borderRadius:"25px"
                 ,backgroundColor:"#E44E1B"
                 ,color:"white"
                 ,textAlign:"center"
                 ,paddingTop:"10px"
                 ,boxShadow:"0px 2px 3px white"
                 ,marginLeft:"25%"
                 ,marginTop:"7%"
                 }} >CONTACT US</button>        
        </div>
        <div className={style.container}>
            <div>
                <img src={DigitalService} alt="Premium Service" className={style.img1}/>
                <div style={{color:"rgba(0,0,0,0.5)",lineHeight:"20px"}}>
                   <div className={style.imgtitle}>Digitally Enabled</div>
                   Connect to our team seamlessly<br/>
                   Enhanced access and repeat visits <br/>
                   Complete view of the care continuum <br/>
                </div>
            </div>
            <div style={{color:"rgba(0,0,0,0.5)"}}>
                <img src={dedication} alt="Premium Service" className={style.img2}/>
                <div>
                   <div  className={style.imgtitle} >Best Product</div>
                   feel the service in pan india
                </div>
            </div>
            </div>
            <ContactForm/>
            <Contact/>
            <BeekeyInfo/>
            <BeekeyGenaralInfo/>
    </div>
    </div> 
)
};

export default Doctors;