import styles from './BuyMedicine.module.css';
import beekey from '../../Assets/beekey.jpeg';
import { useRef,useState,useCallback } from 'react';
import img1 from '../../Assets/devimg.png';
import img2 from  '../../Assets/protected3.png';
import img3 from '../../Assets/camera3.png';
import img4 from '../../Assets/gallery.png';
import img5 from '../../Assets/locker.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/styles.css';
import Webcam from "react-webcam";
import {useParams,Link, useNavigate} from 'react-router-dom';
import  PlaceMedicineOrder from './PlaceMedicineOrder';
import OrderPlaced from './OrderPlaced';
import React from 'react';
import { useSelector } from 'react-redux';


const videoConstraints = {
   width:  400,
   height: 420,
   facingMode: "user"
 };

const BuyMedicine = () => {
   const videoElement = useRef(null);

   const Authstate =  useSelector(state => state.auth);
   const g = useRef(null);
   const inputFile = useRef(null);
   const history = useNavigate();
   let params = useParams();
   const [isShowVideo, setIsShowVideo] = useState(false);
   const [order, setOrder] = useState(false);

   const [imgs,setImgs] = useState([]);

   console.log(imgs)
   const startCam = () => {
      setIsShowVideo(true);
  };
  const handleContinue = (e) =>  {
   console.log("Helloworld")
   if (!Authstate.isAuthenticated){
      history('/login')
   }else{
      history('/Branding/buy_medicine')
   }
};

   const stopCam = () => {
      let stream = videoElement.current.stream;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      setIsShowVideo(false);
  }

  const capture = useCallback(
   () => {
     const imageSrc = videoElement.current.getScreenshot();
     setImgs(prev => [...prev,imageSrc])
     console.log(imageSrc)
   },
   [videoElement]
 );

//  document.getElementById('uploaddiv').onchange = function(e){
//      const uFiles = e.target.files;
//      console.log(uFiles);
//    }


console.log(order,"rrr")
const onClickHandler = (e) => {
    inputFile.current.click();
 };

 const onChangeHandler = (e) => {
   const [file] = e.target.files;
   setImgs(prev => [...prev, URL.createObjectURL(file)]);
 };

 const removeImgHandler = (e) =>{
setImgs(prev => prev.filter(img =>  img !== e))
 };

let displayedImgs  = <div></div>; 
if (imgs.length > 0){
   displayedImgs = <div style={{display:"flex",flexWrap:"wrap",justifyContent:"space-around"}}>
   {imgs.map((e) => 
   <div className={styles.div001}>
      <div className={styles.divclose} onClick={() => removeImgHandler(e)} >X</div>
      <img className = {styles.choosenImg} src={e} alt ="prescription" />
   </div>
   )}
   </div> 
}; 

    return(
        <div className={styles.main}>
        <div style={{marginLeft:"2%",marginTop:"7%",width:"700px"}}>
        <div style={{width:"130px",display:"flex",margin:"4%",}}>
                  <img src={beekey} alt="Beekey Logo"  className={styles.beekeylogo}/>
               <div style={{marginLeft:"10px",marginTop:"10px"}}>
                  Beekey <br/>Innovative
               </div>
              </div>
        <div style={{marginLeft:"10%",marginTop:"10%"}}>
           <p style={{fontSize:"50px",fontWeight:"600",fontFamily:"'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",color:"#DAC9F5",textShadow:"2px 5px #BD9EED"}}>Buy Medicine</p>
           <p style={{fontSize:"27px",fontWeight:"600",color: "rgba(0,0,0,0.5)"}} >Our Developers avaliable anytime,anywhere<br/>
            with 24*7  availble just for you.</p>
            <div style={{display:"flex",marginTop:"20px"}}><img src={img1} alt="protection" className={styles.img}/> <p style={{marginTop:"60px", marginLeft:"10PX",fontSize:"20PX"}}> 24x7 availbe developers</p></div>
            <div style={{display:"flex",marginTop:"40px"}}><img src={img2} alt="protection" className={styles.img}/> <p style={{marginTop:"60px", marginLeft:"10PX", fontSize:"20PX"}}> Completely confidential</p></div>
        </div>
        </div>
        <div style={{width:"25%",borderLeft:"1px solid blue", display:'flex',flexDirection:'column'}}>
         <div style={{display:'flex',height:"6%",padding:'7px',boxShadow:"0px 0px 3px rgba(0,0,0,0.3)"}}>
            <FontAwesomeIcon icon={solid("align-right")} size="2x" className={styles.icon}/>
            <div style={{width:"130px",display:"flex",marginLeft:"25%"}}>
                <img src={beekey} alt="Beekey Logo"  className={styles.beekeylogo}/>
             <div style={{marginLeft:"10px",marginTop:"10px"}}>
                Beekey <br/>Innovative
             </div>
            </div>
         </div>
        {!params.forwardPath && <div>
        <AwesomeSlider animation="cubeAnimation" organicArrows={true} bullets={false} infinite={true}>
         <div style={{height:"230px",width:"90%"
                      ,border:"2px solid #540A36"
                      ,margin: "5%",borderRadius:"10px"
                      ,color:"white"
                      ,textAlign:"center"
                      ,backgroundImage: "linear-gradient(to right,#756798, white)"}}>
          <p style={{margin:"25%",fontSize:"20px"}}>Put some content</p>
         </div>
         <div style={{height:"230px",width:"90%"
                      ,border:"2px solid #540A36"
                      ,margin: "5%",borderRadius:"10px"
                      ,color:"white"
                      ,textAlign:"center"
                      ,backgroundImage: "linear-gradient(to right,#540A36, white)"}}>
          <p style={{margin:"25%",fontSize:"20px"}}>Put some content</p>
         </div>
         <div style={{height:"230px",width:"90%"
                      ,border:"2px solid #540A36"
                      ,margin: "5%",borderRadius:"10px"
                      ,color:"white"
                      ,textAlign:"center"
                      ,backgroundImage: "linear-gradient(to right,#EA1515, white)"}}>
          <p style={{margin:"25%",fontSize:"20px"}}>Put some content</p>
         </div>
         <div style={{height:"230px",width:"90%"
                      ,border:"2px solid #540A36"
                      ,margin: "5%",borderRadius:"10px"
                      ,color:"white"
                      ,textAlign:"center"
                      ,backgroundImage: "linear-gradient(to right,#E4951B, white)"}}>
          <p style={{margin:"25%",fontSize:"20px"}}>Put some content</p>
         </div>
         </AwesomeSlider>
            <div>
                {isShowVideo ?
                    <div className={styles.content022}>
                    <Webcam  className={styles.camera} audio={false} ref={videoElement} videoConstraints={videoConstraints} screenshotFormat="image/jpeg"/>
                    <div className={styles.content023}>
                       <button className={styles.button001} onClick={capture}>Capture photo</button> 
                       <button className={styles.button002} onClick={stopCam}>Close</button>
                    </div>
                    </div>:
                      <div className={styles.content020} >
                      <div  onClick={startCam} className={styles.content021}>
                      <img  className={styles.img002} src={img3}  alt="camera"></img>
                         Take photo 
                      </div>
                      <div className={styles.content021} onClick={onClickHandler} onChange={onChangeHandler}id='uploaddiv'>
                        <img className={styles.img003} src={img4} alt="gallery"/>
                         <input type="file" id="file" ref={inputFile} style={{ display: "none" }} accept="image/*"/>
                         Gallery
                      </div>
                      <div className={styles.content021}>
                      <img  className={styles.img004} src={img5} alt="health locker"/>
                        Health Locker
                      </div>
                    </div>   
                }
            </div> 
              {imgs.length > 0 ? displayedImgs: null}
         <div className={styles.content014}>
            <p  className={styles.para010}>Easy Process to buy medicines</p>
            <div className={styles.content015}>
               <div className={styles.content016}>
                  <div className={styles.content017}>1</div>
                  <p  className={styles.para011}>Upload valid prescription</p>
               </div>
               <div className={styles.content016}>
                  <div className={styles.content017}>2</div>
                  <p  className={styles.para011}>Receive confirmation call</p>
               </div>
               <div className={styles.content016}>
                  <div className={styles.content017}>3</div>
                <p  className={styles.para011}>Receive comparison quote</p>
               </div>
               <div className={styles.content016}>
                  <div className={styles.content017}>4</div>
                <p  className={styles.para011}>Delivered to your location</p>
               </div>
            </div>
         </div>
         { imgs.length > 0 &&
         <div onClick={handleContinue} className={styles.button003}>
            <div>Continue</div>
            <FontAwesomeIcon icon={solid("circle-chevron-right")} size='1x' color={'white'}/>
         </div>
         }
        </div>}
        {params.forwardPath === 'buy_medicine' &&
       <PlaceMedicineOrder  presciption ={imgs}/>
       }
       {params.forwardPath  === 'oderplaced' && 
       <OrderPlaced />
       }
      </div>
      </div>

    )
};

export default BuyMedicine;