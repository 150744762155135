import styles from  './medicinetab.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'; 
import {useNavigate} from 'react-router-dom';
import React from 'react';
import firstaidbox from '../../Assets/firstaidbox.png'

const Medcinetab = (props) => {

    const history = useNavigate();

   const onClickHandler = () => { 
    if(props.id==="001"){
      history('/webdev')
    }else if(props.id ==="002"){
      history('/DigitalMarketing')
    }else if (props.id === "003"){
      history("/Branding")
    }else if (props.id === "004"){
     history("/LogoDesign")
    }else if (props.id ==="005"){
     history("/MenuCard")
    }
    else{
        console.log("clicked on some thing else")
    }
};

    console.log(props.img,props.id);
    let icon =  <div>icon unavailable</div>
    let color= 'white';
    if(props.id==="001"){
        icon = <FontAwesomeIcon icon={solid("kit-medical")} size='2x' className={styles.market}/>
        color='black';}
    else if(props.id==="002"){
        icon = <FontAwesomeIcon icon={solid("calendar-check")} size='2x' className={styles.market}/>
        color='black';
    }else if(props.id==="003"){
        icon = <FontAwesomeIcon icon={solid("truck-medical")} size='2x' className={styles.brand}/>
        color='black';
    }else if(props.id==="004"){
        icon = <FontAwesomeIcon icon={solid("flask-vial")} size='2x' className={styles.logo}/>
        color='red'
    }else{
        icon =<FontAwesomeIcon icon={solid("user-doctor")} size='2x' className={styles.menu}/>
        color='black'
    };

    return (
        <div className={styles.card} onClick={onClickHandler}>
            {icon}
            <div style={{fontWeight:"700", marginTop:"0"}}>{props.icon}</div  >
            <div style={{fontWeight:"300",fontSize:"13px", marginBottom:"auto"}}>{props.description}</div>
            <div style={{display:"flex",justifyContent:"space-between",alignItems: "flex-end"}}>
                <div style={{fontWeight:"300",fontSize:"13px",color:color}}><button type="">{props.message}</button></div>
                {/* <FontAwesomeIcon icon={solid("circle-chevron-right")} size='1x' color={color}/> */}
                </div>
        </div>
    )

};

export default Medcinetab;
