import img1 from '../../Assets/beekeymedic.jpeg';
import img2 from '../../Assets/login.ico';
import img3 from '../../Assets/search.png';    
import img4 from '../../Assets/emptybox.png'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import  styles  from './MedicalShop.module.css';
import SideBar from '../SideBar/SideBar';
import { useEffect, useState } from 'react';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import {useParams,Link} from 'react-router-dom';
import MedicineOrder from './MedicineOrder';
import FullOrderData from './fullOrderData';
import React from 'react';
import  MedicalShoplogin from './ MedicalShoplogin';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import DashboardSection from './DashboardSection';
import PersonalSection from './PersonalSection';
import EarningSection from './EarningSection';
import BeekeyImage from '../../Assets/bm-icon-3.png';
import dashboard from '../../Assets/dashboard.png';
import activebookings from '../../Assets/activebookings.png';
import totalbookings from '../../Assets/totalbookings.png';
import earnings from '../../Assets/earnings.png';
import logout from '../../Assets/logout.png';
import { GetApI } from '../ApiModules/ApiInterFace';
import { useDispatch } from 'react-redux';
import { AuthAction } from '../../Store/Store';
import usericon from'../../Assets/user-icon.png';

// const client = new W3CWebSocket('ws://0.0.0.0:6001/ws');
const drawerWidth = 240;
const MedicalShop = (props) =>{
    let params = useParams();
    const [websocket, setWebsocket] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState({ordernumber:"undefined"});
    const [selectedOrder2, setSelectedOrder2] = useState({ordernumber:"undefined"});
    const [AllOrder, setAllOrder] = useState([])
    const [pending, setPending] = useState([]);
    console.log(pending,"LLLL")
    const [accepted, setAccepted]= useState([]);
    console.log(accepted,"oooooooo")
    const [readyForPickUp, setReadyForPickUp] = useState([]);
    const [view,setView] = useState('ALL')
    console.log("444444",view);
    const [deliverd, setDeliverd] = useState([]);
    const [outOfDelivery, setOutOfDelivery] = useState([]);
    const [cancelled, setCancelled] = useState([]); 

    
    const { window } = props;
    const dispatch = useDispatch();
    const [mobileOpen, setMobileOpen] = React.useState(false);
  
  
   
    const [showLogout, setShowLogout] = useState(false);
  
    const onLogOutOutput = (Response) => {
         //setLoading(false);
         localStorage.removeItem("beekey-doctor");
         dispatch(AuthAction.logout())
      }
  
    const logoutfun = async () => {
      console.log("calledlogin")
      //setLoading(true)
      GetApI({path:"/logout/doctor"
               ,callbackfunc:onLogOutOutput
             });  
  }
  
    function toggleLogout() {
      console.log("logout")
      setShowLogout(!showLogout);
    }
    
  
    const [activeSection, setActiveSection] = useState("section1");
    function handleSectionClick(section) {
      setActiveSection(section);
    }
  
  
  
  
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };


    const drawer = (
      <div>
        {/* <Toolbar /> */}
        <div className={styles.logo}>
          <img src={BeekeyImage} alt=""/>
          </div>
        <div className={styles.profile}>
          <img src={usericon}  alt=""/>
          <div className={styles.text}>
              <h1>MedicalShop</h1>
              <p>Madhu</p>
          </div>
        </div>
       
        <List>
            <ListItem className={styles.listitem}>   <img src={dashboard} alt=""/><div><a onClick={() => handleSectionClick("section1")} className={activeSection === "section1" ? "active" : ""}>Dashboard</a></div></ListItem> 
            <ListItem className={styles.listitem}>   <img src={activebookings} alt=""/><div><a onClick={() => handleSectionClick("section2")}>Current Orders</a></div></ListItem> 
            <ListItem className={styles.listitem}>   <img src={totalbookings} alt=""/><div><a onClick={() => handleSectionClick("section3")}>Order History</a></div></ListItem> 
            <ListItem className={styles.listitem}>   <img src={earnings} alt=""/><div><a onClick={() => handleSectionClick("section4")}>Earnings</a></div></ListItem> 
            <ListItem className={styles.listitem}>    <img src={usericon} alt=""/> <div><a onClick={() => handleSectionClick("section5")}>Perofile</a></div></ListItem> 
            <ListItem className={styles.listitem}>    <img src={logout} alt=""/> <div><div onClick={logoutfun}>Logout</div></div></ListItem> 
                
        </List>
      </div>
    );




    const container = window !== undefined ? () => window().document.body : undefined;







console.log("$$$$$$$$",selectedOrder2);

const menu = [    
{name:'Orders',link:'/MedicalShop/orders'},    
{name:'Order History',link:'/MedicalShop/order_history'}
// {name:'Outlets',link:'/MedicalShop/outlets'}
]




















// const triggerDropDown = () => {
//     let div = document.getElementById('selection panel');
//     if (getComputedStyle(div).display === 'block'){
//           div.style.display = 'none'
//         }else{
//           div.style.display = 'block'   
//         }
//     }


// const triggerHandle = () => {
// let div = document.getElementById('medical shops');
// let icon = document.getElementById('righticon');
// if (getComputedStyle(div).display === 'block'){
//       div.style.display = 'none'
//       icon.style.transform  = 'rotate(0deg)';
//     }else{
//       div.style.display = 'block'   
//       icon.style.transform  = 'rotate(90deg)';
//     }
// }

const handle_message = (message,client) => {
  const response = message.data;
  const value = JSON.parse(response);
  console.log("ee", value)
  switch(value["type"]){
   case "keep going":
    if (value["application"] === "pong"){
      setTimeout(() => {
        client.send(JSON.stringify({"application":"ping"}))
      }, 3000);
    };
    break;
   case "load_value":
    console.log(value,"&&&&7")
    setPending(value["pending"]);
    setAccepted(value["accepted"]);
    setReadyForPickUp(value["ready_for_pickup"])
    setDeliverd(value["deliverd"])
    setOutOfDelivery(value["delivery_started"])
    setCancelled(value["cancelled"])
    setAllOrder([...value["pending"],
                ...value["accepted"],
                ...value["ready_for_pickup"],
                ...value["deliverd"],
                ...value["delivery_started"],
                ...value["cancelled"]
              ])
    break;
  default:
    console.log('inavlid message',value)
    }
};

useEffect(() => {
  // console.log("cxxzcx")
  const client = new W3CWebSocket('wss://beekeymedics.com/ws');
   
  client.onopen = () => {
    setWebsocket(client)
    console.log('WebSocket Client Connected');
      client.send(JSON.stringify({"application":"ping"}))
  };
  client.onmessage = (message) => {
    handle_message(message, client);
    console.log(message,"dd");
  };

  return () =>{
    console.log("called closing websocket")
    client.close()
    }

},[])

const data = {name:"sanadca",age:"23"};

const logInUser = () => {
  const username = "sanadca";
  if (username.trim()) {
      websocket.send(JSON.stringify({
        ...data,
        type: "userevent"
      }));
    };
  }

 const handleSelectOrder = (order) => {
  console.log(order,"%ee%%%%%%%%")
  setSelectedOrder((prev) =>{
  if (prev.ordernumber === order){
   return({ordernumber:"undefined"})}
  else{return({ordernumber:order})}  
  })}; 

  let orderHistory  = null
  console.log("gdsrg",cancelled)
  if (view === "deliverd"){
    orderHistory = deliverd
  }else if (view === "delivery_started"){
    orderHistory = outOfDelivery
  }else if (view === "cancelled"){
    orderHistory = cancelled
  }else if (view === "ALL"){
    orderHistory = [...cancelled,...outOfDelivery,...deliverd]
  }

  console.log(view,"bhngh","\n",orderHistory)
let completOrderData = [];  
if(selectedOrder.ordernumber !== "undefined"){


  const element = AllOrder.filter((e) =>{ 
  console.log(e["uuid"] === selectedOrder.ordernumber["uuid"]);
  return(e["uuid"] === selectedOrder.ordernumber["uuid"])});
  if (element !== undefined){
    completOrderData = element[0]
  }
}else(
  console.log("NoNo")
)

let completOrderData2 = []; 

if(selectedOrder2.ordernumber !== "undefined"){
  const TotalList  = [].concat.apply([],[deliverd,outOfDelivery,cancelled]) 
  const element = TotalList.filter((e) => e["number"] === selectedOrder2["ordernumber"])[0];
  if (element !== undefined){
    completOrderData2 = element
  }
}

console.log(completOrderData2,"6666666666666666666666666666")
const UpdateOrder = (updatedOrder) => {
  console.log('updateExistingOrde');
  websocket.send(JSON.stringify({
    order: updatedOrder,
    type: "updateExistingOrder"
  }));
}

const s =  pending.map((e) => <MedicineOrder orders={e} selectOrder={(order) => handleSelectOrder(order)} selectedOrder={selectedOrder} />)
const y =  accepted.map((e) => <MedicineOrder orders={e} selectOrder={(order) => handleSelectOrder(order)} selectedOrder={selectedOrder} />)
const z =  readyForPickUp.map((e) => <MedicineOrder orders={e} selectOrder={(order) => handleSelectOrder(order)} selectedOrder={selectedOrder} />)


const handleSetSelectedOrder2 = (number) => {
  setSelectedOrder2((prev) => {
   if (prev["ordernumber"] === number)
  {return({ordernumber:"undefined"})}
  else {return({ordernumber:number})}
})
};

const orderHistoryRows  =

orderHistory.map((e) => {
var timestamp = e["a_ctime"];  
var date = new Date(timestamp);
const Time = date.getDate() + " " + date.toLocaleString('en-us', { month: 'short' }) + date.getFullYear() +" - " +
date.toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true })
let Status = null;
let StatuStyle = "";
if (e["status"] === "deliverd"){
  Status =  "Deliverd"
  StatuStyle = "div0028"}
  else if (e["status"] === "delivery_started"){
    Status = "Out Of Delivery"
    StatuStyle = "div0028a"
  }else{ Status = "Cancelled"
  StatuStyle = "div0028b"
}
return(
<tr onClick={() => handleSelectOrder(e)}>
<td>{e["uuid"]}</td>
<td>{Time}</td>
<td><div className={styles[StatuStyle]}>
 <FontAwesomeIcon icon={solid("user-check")} size="1x" className={styles.icon}  />
       {Status}</div></td>
</tr>)})

const OrderHistoryTag = <div className={styles.div0007}>
<div className={styles.div0008a}>
    <div className={styles.div0017}>
      <div className={styles.div0018}>
      <div className={view ==="ALL" ? styles.div0016active : styles.div0016 } onClick={() => setView("ALL")} >All</div>
      <div className={view ==="delivery_started" ? styles.div0016active : styles.div0016 } onClick={() => setView("delivery_started")}>Out For Delivery</div>
      <div className={view ==="deliverd" ? styles.div0016active : styles.div0016 } onClick={() => setView("deliverd")}>Deliverd</div>
      <div className={view ==="cancelled" ? styles.div0016active : styles.div0016 } onClick={() => setView("cancelled")}>Cancelled</div>
      </div>
      <div className={styles.div0018a}> 
        <span className={styles.span002}>Date</span>
        <div className={styles.div0019}>
          <div><input  className={styles.input0003} type="date" placeholder="Start Date" min="23/08/2022"></input></div>
          <div><FontAwesomeIcon icon={solid("left-right")} color="rgb(121, 118, 118)" className={styles.icon001}/></div>
          <div><input className={styles.input0003}  type="date"  placeholder="End Date"></input></div>
        </div>
      </div>

    </div>
    <div className={styles.div0010}>
        <img src={img3} className={styles.img002} alt="search"></img>
        <input type='text' placeholder='search' className={styles.input0002}></input>
   </div>
   <div>
      <div>
        <table className={styles.tabel0001}>
        {orderHistoryRows}   
        </table>
      </div>
   </div>
  </div>
  <div className={styles.div0009}>
     {selectedOrder.ordernumber === "undefined" ?
       <div className={styles.div0015a}>
       <img src={img4}  className={styles.img003} alt="empty"></img>
      <p>please Select Any Order</p>
      </div> :
      <FullOrderData  completOrderData={completOrderData} changeOrderStatus={(updatedOrder)=> UpdateOrder(updatedOrder)}/>
      }
  </div>
</div>;

const OrderTag = <div className={styles.div0007}>
<div className={styles.div0008}>
   <div className={styles.div0010}>
     <img src={img3} className={styles.img002} alt="search"></img>
     <input type='text' placeholder='search' className={styles.input0002}></input>
   </div>
   <div className={styles.div0011}>
     <div className={styles.div0012}>
         <div className={styles.div0013}>Pending</div>
         <div className={styles.div0014}>
             { pending.length === 0   ? <div className={styles.div0015}>
             <img src={img4}  className={styles.img003} alt="empty"></img>
             <p>No Pending Order</p>
             </div> : s
             }
         </div>
     </div>
     <div className={styles.div0012}>
         <div className={styles.div0013}>Accepted</div>
         <div className={styles.div0014}>
            {accepted.length ===  0 ? <div className={styles.div0015}>
              <img src={img4}  className={styles.img003} alt="empty"></img>
              <p>No Accepted Order</p>
             </div> : y
             }
         </div>
     </div>
     <div className={styles.div0012}>
        <div className={styles.div0013}>Ready for Pickup</div>
        <div className={styles.div0014}>
          {readyForPickUp.length === 0 ? <div className={styles.div0015}>
            <img src={img4}  className={styles.img003} alt="empty"></img>
             <p>No Ready for  PickUp Order</p>
          </div> : z } 
        </div>
     </div>
     
   </div>
</div> 
<div className={styles.div0009}>
 {selectedOrder.ordernumber === "undefined" ?
    <div className={styles.div0015a}>
    <img src={img4}  className={styles.img003} alt="empty"></img>
   <p>please Select Any Order</p>
   </div> :
   <FullOrderData    completOrderData={completOrderData} changeOrderStatus={(updatedOrder)=> UpdateOrder(updatedOrder)}/>
   }
</div>
</div>;


return(
  <MedicalShoplogin>
  <Box sx={{ display: 'flex',background:"#e7f0f0" }}>
  <CssBaseline />
  <AppBar
    position="fixed"
    sx={{
      width: { sm: `calc(100% - ${drawerWidth}px)` },
      ml: { sm: `${drawerWidth}px` },
    }}
  >
    <Toolbar className={styles.header}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div">
        MedicalShop 
      </Typography>
      <div >
        
        <div className={styles.profileicon} onClick={toggleLogout}>
        {showLogout && (

                 <div className={styles.div001}> 
                  <img src={logout} alt="" style={{width: "25px"}}/>
                  <div  onClick={logoutfun}>
                  Logout
                  </div>
                </div>
            )}
            <img src={usericon} alt="User Avatar" />
            
        </div>

      </div>
    </Toolbar>
  </AppBar>
  <Box
    component="nav"
    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    aria-label="mailbox folders"
  >
    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      {drawer}
    </Drawer>
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
      open
    >
      {drawer}
    </Drawer>
  </Box>
  <Box
    component="main"
    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
  >
    <Toolbar />
        <div>
            {activeSection === "section1" && <DashboardSection />}
            {activeSection === "section2" && <>{OrderTag} </>}
            {activeSection === "section3" && <>{OrderHistoryTag}</>}
            {activeSection === "section4" && <EarningSection />}
            {activeSection === "section5" && <PersonalSection />}
        </div>
       
    
  </Box>
</Box>
</MedicalShoplogin>
)
};

export default MedicalShop;