import React from 'react';
import styles from './Slider.module.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from '../../../Assets/banner1.jpg'

const ResponsiveSlider = ({slides}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable automatic sliding
    autoplaySpeed: 3000, // Set the interval between slides (in milliseconds)
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.slider}>
      <Slider {...settings}> 
        {slides.map((slide,index)=>(
          <div className={styles.div1} key={index}>
          <img src={slide.image} alt={`Slide ${index + 1}`} />
        </div>
        ))}
        {/* <div className={styles.div1}>
          <img src={img1} alt="Slide 1" />
        </div>
        <div className={styles.div1}>
          <img src={img1} alt="Slide 2" />
        </div>
        <div className={styles.div1}>
          <img src={img1} alt="Slide 3" />
        </div> */}
        {/* Add more slides as needed */}
      </Slider>
    </div>
  );
};

export default ResponsiveSlider;
