import styles from './QualityOffers.module.css';
import img1 from '../../Assets/buisness.jpg';
import img2 from '../../Assets/hyper.jpeg';
import img3 from '../../Assets/nano.webp';
// import { Slide } from 'react-slideshow-image';
// import 'react-slideshow-image/dist/styles.css';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/styles.css';
import './All.css';
import React from 'react';

const Quality = [
  {id:"001",
  head: "Instant Help",
  content:<div className={styles.text001}>  Reach our employees <br/>Get In touch with our Employees for any technicalissue<br/>Get personalized consultation on Medical and branding support </div>,
  button:"Contact Receptionist"},
  {id:"002",
  head: "Book Appoinment",
  content:<div className={styles.text001}> Skilled developers you can trust<br/>Our Employees have been chosen through rigourous selection process with the aim of delivering a better experience and care</div>, 
  button:"Book Appoinment Now"
  },  
  {id:"003",
  head:"It services",
  content:<div className={styles.text001}>Get them dekiverd at  your doorstep<br/> Now Branding just a click away.Choose amongst multiple partners and offersand make a transperant choice</div>,
  button:"it sevices"}];

const QualityOffers = (props) => {

  console.log("65787uy6r67",props.index)
    return(
      <div className={styles.mainbody}>
       <div className={styles.bodypart001}>
       <AwesomeSlider  className={styles.slider} organicArrows={true} bullets={false} infinite={true} selected={props.index}>
      {Quality.map((e,index) =>
       { let A = "";
        if(e.id === "001"){
          A = <img className={styles.img001} src={img1} alt="pic of service"/>
        }else if(e.id === "002"){
          A = <img className={styles.img002}  src={img2} alt="pic of service"/>
        }else {
          A = <img className={styles.img003}  src={img3} alt="pic of service"/>
        }
        
        return(
      <div className={styles.main} key={e.id}>
        <div className={styles.content}>
            <div className={styles.head}>{e.head}</div>
            <div className={styles.contentext}>{e.content}</div>
            <button className={styles.button}>{e.button}</button>
        </div>
        {A} 
      </div>)})}
      </AwesomeSlider>
        </div>
      </div>
    )
};

export default QualityOffers;