import {useState} from "react";
import styles from "./HealthLocker.module.css";
import OtpInput from "react-otp-input";
import img3 from '../../../Assets/share.png';
import img2 from '../../../Assets/accessfile.webp';
import img1 from '../../../Assets/locker2.png';
import Slideshow from "../../UI/Slideshow";
import img4 from '../../../Assets/folder.webp';
import img5 from '../../../Assets/file2.png';
import React from 'react';

const  HealthLocker = (props) => {

const  [lockerStatus,setLOckerStatus] = useState("lockerid not created"); 
console.log(lockerStatus)
const [pin, setPin] = useState("");
const [confirmPin, setConfirmPin] = useState("");


const handleChangePin  = (code) => setPin(code);
const handleChangeConfirmPin  = (code) =>  {
  setConfirmPin(code);
};

const onSubmit = () => {
  if (pin === confirmPin){
    setLOckerStatus('lockerid created')
  } 
}
const onSubmitAuth = () => {
  if (pin === confirmPin){
    setLOckerStatus("Authenticated")
  } 
}
const  sliderInputs = [{text:"Upload store and share file easily",bgcolor:"#55d3ec",border:"#19bada", color:'white'},
                {text:"All prescription, lab reports and invoices are at one place",bgcolor: "#00d7af",border:"#00a586", color:'white'},
                {text:"Access controlled and Pin protected",bgcolor:"#FFBB28", border:"#d49100",color:'white'}]
   if (lockerStatus === 'lockerid not created'){             
return(
      <div>
     <div className={styles.div0001}>
      <p className={styles.para0001}>setting 4 digit pin</p> 
      <OtpInput
        value={pin}
        onChange={handleChangePin}
        numInputs={4}
        separator={<span style={{ width: "8px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        isInputSecure={true}
        inputStyle={{
          border: "1px solid transparent",
          borderRadius: "8px",
          width: "54px",
          height: "54px",
          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          placeholder:"*",
          border: "1px solid #CFD3DB"
        }}
        focusStyle={{
          border: "1px solid blue",
          outline: "none"
        }}
      />
      <p className={styles.para0002}>Please Enter  A Memorable 4 digit Pin</p>
      <OtpInput
        value={confirmPin}
        onChange={handleChangeConfirmPin}
        numInputs={4}
        separator={<span style={{ width: "8px" }}></span>}
        isInputNum={true}
        shouldAutoFocus={true}
        isInputSecure={true}
        inputStyle={{
          border: "1px solid transparent",
          borderRadius: "8px",
          width: "54px",
          height: "54px",
          fontSize: "12px",
          color: "#000",
          fontWeight: "400",
          caretColor: "blue",
          placeholder:"*",
          border: "1px solid #CFD3DB",
        }}
        focusStyle={{
          border: "1px solid blue",
          outline: "none"
        }}
      />
      <p className={styles.para0002}> Please Confirm Your Pin</p>
      <button onClick={() => onSubmit()}>submit</button>
    </div>
    <div  className={styles.div0002}>
      <p  className={styles.para0005}>Benefits of Health Locker</p>
      <Slideshow  sliderInputs={sliderInputs}/> 
    </div> 
    <div className={styles.div0002a}>
        <p className={styles.para0005}>How to use</p>
        <div className={styles.div0003}>
            <img className={styles.img0001} src={img1} alt="lock" ></img>
            <p className={styles.para0004}>Set An Access Code</p>
        </div>
        <div  className={styles.div0004}></div>
        <div className={styles.div0003}>
            <img className={styles.img0001} src={img2} alt="acess file" ></img>
            <p className={styles.para0004}>Access All Your Files Securely</p>
        </div>
        <div className={styles.div0004}></div>
        <div className={styles.div0003}>
            <img className={styles.img0001} src={img3} alt="share" ></img>
            <p className={styles.para0004}>Upload, Organize, and Share files securely</p>
        </div>
    </div>
    </div>
)}else if(lockerStatus === 'lockerid created'){
    return(
    <div>
    <div className={styles.div0001}>
     <p className={styles.para0001}>All your medical records<br/> at your fingertips</p>
     <OtpInput
       value={confirmPin}
       onChange={handleChangeConfirmPin}
       numInputs={4}
       separator={<span style={{ width: "8px" }}></span>}
       isInputNum={true}
       shouldAutoFocus={true}
       isInputSecure={true}
       inputStyle={{
         border: "1px solid transparent",
         borderRadius: "8px",
         width: "54px",
         height: "54px",
         fontSize: "12px",
         color: "#000",
         fontWeight: "400",
         caretColor: "blue",
         placeholder:"*",
         border: "1px solid #CFD3DB",
       }}
       focusStyle={{
         border: "1px solid blue",
         outline: "none"
       }}
     />
     <p className={styles.para0002}> Enter 4 digit passcode to access</p>
     <button onClick={() => onSubmitAuth()}>submit</button>
    <div className={styles.div0005}>
    <p className={styles.para0006}> Change Pin ?</p>
    <p className={styles.para0006}> Forgot Pin ?</p>
   </div>

   </div>
   <div  className={styles.div0002}>
     <p  className={styles.para0005}>Benefits of Health Locker</p>
     <Slideshow  sliderInputs={sliderInputs}/> 
   </div> 
   <div className={styles.div0002a}>
       <p className={styles.para0005}>How to use</p>
       <div className={styles.div0003}>
           <img className={styles.img0001} src={img1} alt="lock" ></img>
           <p className={styles.para0004}>Set An Access Code</p>
       </div>
       <div  className={styles.div0004}></div>
       <div className={styles.div0003}>
           <img className={styles.img0001} src={img2} alt="acess file" ></img>
           <p className={styles.para0004}>Access All Your Files Securely</p>
       </div>
       <div className={styles.div0004}></div>
       <div className={styles.div0003}>
           <img className={styles.img0001} src={img3} alt="share" ></img>
           <p className={styles.para0004}>Upload, Organize, and Share files securely</p>
       </div>
   </div>
   </div>)}else if(lockerStatus === "Authenticated"){
    return(
        <div>
            <div className={styles.div0006}>
                <p>Share with Care Team</p>
                <label className={styles.switch}>
                      <input type="checkbox"/>
                      <span className={styles.slider}></span>
                </label>
            </div>
            <div>
            <div className={styles.div0006a} onClick={() => setLOckerStatus("Consultation & Presicription")}>
              <img src={img4} alt='folder' className={styles.img0002}/>
              <div style={{textAlign:'left',marginLeft:'35px', width:'280px'}}>
                <p className={styles.para0007}>Consultation & Presicription</p>
                <p className={styles.para0008}> 7 reports</p>
              </div>
              <div className={styles.div0007}>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
              </div>
            </div>
            <div className={styles.div0006a}>
              <img src={img4} alt='folder' className={styles.img0002}/>
              <div style={{textAlign:'left',marginLeft:'35px',width:'280px'}}>
                <p className={styles.para0007}>Lab tests</p>
                <p className={styles.para0008}> 7 reports</p>
              </div>
              <div className={styles.div0007}>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
              </div>
            </div>
            <div className={styles.div0006a}>
              <img src={img4} alt='folder' className={styles.img0002}/>
              <div style={{textAlign:'left',marginLeft:'35px',width:'280px'}}>
                <p className={styles.para0007}>Consultation & Presicription</p>
                <p className={styles.para0008}> 7 reports</p>
              </div>
              <div className={styles.div0007}>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
              </div>
            </div>
            </div>
        </div>
    )
   }else if(lockerStatus === "Consultation & Presicription"){
    return(<div> 
              <div className={styles.div0006b}>
              <img src={img5} alt='folder' className={styles.img0003}/>
              <div style={{textAlign:'left',marginLeft:'35px',width:'280px'}}>
                <p className={styles.para0007a}>Rx7gftvw9xbjdb</p>
                <p className={styles.para0008}>Uploaded Record</p>
                <p className={styles.para0008}>Sun,Aug 7, 2022, 1:26 PM </p>
              </div>
              <div className={styles.div0007}>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
              </div>
            </div>
            <div className={styles.div0006b}>
              <img src={img5} alt='folder' className={styles.img0003}/>
              <div style={{textAlign:'left',marginLeft:'35px',width:'280px'}}>
                <p className={styles.para0007a}>Rx7gftvw9xbjdb</p>
                <p className={styles.para0008}>Uploaded Record</p>
                <p className={styles.para0008}>Sun,Aug 7, 2022, 1:26 PM </p>
              </div>
              <div className={styles.div0007}>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
              </div>
            </div>
            <div className={styles.div0006b}>
              <img src={img5} alt='folder' className={styles.img0003}/>
              <div style={{textAlign:'left',marginLeft:'35px',width:'280px'}}>
                <p className={styles.para0007a}>Rx7gftvw9xbjdb</p>
                <p className={styles.para0008}>Uploaded Record</p>
                <p className={styles.para0008}>Sun,Aug 7, 2022, 1:26 PM </p>
              </div>
              <div className={styles.div0007}>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
                <div className={styles.div0008}></div>
              </div>
            </div>
    </div>

    )
   }

};



export default HealthLocker;