// PackagePage.js
import React from 'react';
import Package from '../Packages/Package';
import styles from './PackagePage.module.css';

const PackagePage = ({ userPackage, otherPackages }) => {
  return (
    <div className={styles.container}>
      <div className={styles.package}>
        <h2>Your Chosen Package</h2>
        <Package {...userPackage} />
      </div>
      <div className={styles.package}>
        <h2>Other Available Packages</h2>
        {otherPackages.map((packages, index) => (
          <Package key={index} {...packages} />
        ))}
      </div>
    </div>
  );
};

export default PackagePage;
