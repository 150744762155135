
import * as React from 'react';
import { useState } from "react";
import usericon from'../../Assets/user-icon.png';
import styles from './Dashboard.module.css';






function EarningSection() {
    return <div className={styles.container}>
    <h1>Earning Information</h1>
   



   
   
    <div className={styles.totaltable}>
    <h1></h1>

        
    </div>
    
</div>;
  }
  export default EarningSection;