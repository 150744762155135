import styles from './ContactForm.module.css';
import React from 'react';

const ContactForm  = () => {
return(
    <div className={styles.main}>
      <div style={{height:"200px",margin:"10px"}}>
      <p  style={{fontSize:"43px",fontWeight:"300",padding:"0px",margin:"0px"  }}>Join the exclusive</p>
      <p  style={{fontSize:"40px",fontWeight:"200",marginTop:"0px"}}>Beekey Premium Customer</p>
      <p  style={{fontSize:"23px",fontWeight:"300",marginTop:"0px",color:"#0095ff"}}>For Active Premium Customers only</p>
      </div>
      <form className={styles.form}> 
      <div className={styles.divm}>
       <div>
         <div className={styles.div1}>
           <label>Enter your name</label>
           <input type="text"/>
         </div>
         <div className={styles.div1}>
           <label>City</label>
           <input type="text"/>
         </div>
         <div className={styles.div1}>
           <label>Enter your mobile email</label>
           <input type="email"/>
        </div>
      </div> 
      <div>
        <div className={styles.div1}>
            <label>Enter your mobile number</label>
           <input type="text"/>
        </div>
        <div className={styles.div1}> 
           <label>neede services</label>
           <select>
            <option value="nill">select service</option>
            <option value="Digital Marketing">Digital Marketing</option>
            <option value="Digital Card">Digital Card</option>
            <option value="Website">Website Developement</option>
            <option value="Logo Designing">Logo Designing</option>
            <option value="Branding">Branding</option>
            <option value="Design MenuCard">Design MenuCard</option>
           </select>
        </div>
        <div className={styles.div1}>
            <label>How do you know about as?</label>
            <select>
            <option value="nill">select medium</option>  
            <option value="News paper">News paper</option>
            <option value="social media">Socila Media</option>
            <option value="Freinds"> Friends</option>
           </select>
        </div>
      </div>
      </div>
      <div style={{fontSize:"15px",color:"rgba(0,0,0,0.8)",marginLeft:"-6%"}}>
       <input type="checkbox" id="auth" name="authbox" value="Authed"/>
       <label for="vehicle1"> I authorize Beekey to contact me and I understand that this will override the DND status on my mobile number</label>
       </div>
       <button className={styles.button}>CONTACT US</button>
      </form>
    </div>
)};

export default ContactForm;