
import './App.css';
// import MainHeader  from './Component/MainHeader';
//import { Fragment } from 'react';
import Home from './Component/Home';
import DoctorRegister from './Component/DoctorRegister';
import { Route, Routes } from 'react-router-dom';
import { useState,useEffect } from 'react';
// import SideBar from './Component/SideBar/SideBar';
import InstaDoctor from './Component/Offers/InstaDoctor';
import DoctorAppoinment from './Component/Offers/DoctorAppoinment';
import BuyMedicine from './Component/Offers/BuyMedicine';
import Lab from './Component/Offers/Lab';
import FamilyDoctor from './Component/Offers/FamilyDoctor';
import Test1 from './Component/Offers/Test1';
import PayementGateway from './Component/Offers/PayementGateway';
import Login from './Component/Offers/Login';
// import Partnerlogin from './Component/MedicalShop/Partnerlogin';
import DeliveryBoysLogin from './Component/DeliveryBoys/DeliveryBoysLogin';
import MedicalShop from './Component/MedicalShop/MedicalShop.js';
// import Map from './Component/UI/Map';
import {PostAPI, GetApI} from './Component/ApiModules/ApiInterFace';
import { useCookies } from 'react-cookie';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AuthAction } from './Store/Store';
import { getListSubheaderUtilityClass } from '@mui/material';
import MyComponent from './Component/UI/MyComponent';
import DoctorDashboard from './Component/Doctor/Dashboard';
import LabPortal from './Component/Lab/LabPortal';
import DoctorList from './Component/Offers/DoctorList.js';
import DoctorDetails from './Component/Offers/DoctorDetails.js'

//import { Route, Link, NavLink, Redirect,Routes} from "react-router-dom";
function App() {
  

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['cookie']);
  console.log(document.cookie,"dfdvedfs")
  console.log(cookies,"cookies")

  function onClickMe() {
    console.log('clicked')
    setCookie('name', 'dmxcgvhjsdj', { path: '/' });
    console.log(cookies,"newcookie")
  };


  console.log(process.env,"iiiiiiiiii");

  const [showSideBar, setShowSideBar] = useState(false);

  const onCLickIconHandler = () =>{
    setShowSideBar((prev) => !prev);
  };

const handleClientAuth = (response) => {
  if (response.outcome === "success"){
     localStorage.setItem("beekey-app",response["beekey-app"]);
  }else{
    console.log(response, "error") 
  }}


  const getusers = (Data) => {
    if (Data.outcome === "success"){
      console.log(Data,"ppppppppppp")
       dispatch(AuthAction.login(Data.user_data)) 
    }else{
      PostAPI({path:"/login/client?beekey-app=a42cfc7d-f62a-4aee-8910-fb23a84a4d04"
               ,body:JSON.stringify({})
               ,type:'application/x-www-form-urlencoded'
              ,callbackfunc:handleClientAuth});
    }
  };

  


  useEffect(() => {
    console.log('trying to hit')
        GetApI({path:"/getUser",callbackfunc:getusers}) 
    //   setTimeout(function(){PostAPI({
    //     path:"/login/user/",
    //     body:JSON.stringify({"email" : "sanad@gmail.com",
    //     "password": "Sanadca1998"}),
    //     type:'application/json'
    //  })},5000)
    
    // const C =  GetApI('/getUser/55e79d39-0410-4a87-bf49-bb4990456073')
    // console.log(A,"1233cfjgnvkbh")
  },[]);

  return (
    <div className="main">
      {/* <MainHeader iconClicked={onCLickIconHandler} />
     <SideBar show={showSideBar} close={onCLickIconHandler}/>  */}
    <Routes>
      <Route
      path="/"
      element= {<Home showSideBar={showSideBar} close={onCLickIconHandler}/>} />
      <Route 
        path="/DoctorRegister"
        element= {<DoctorRegister showSideBar={showSideBar} close={onCLickIconHandler}/>} />
      <Route
       path="/login"
       element={<Login/>}
      ><Route path=":forwardPath" element={<Login/>}></Route></Route>   
      <Route
        path="/InstaDoctor"
        element = {<InstaDoctor/>} /> 
      <Route 
      path="/DoctorAppoinment"
      element={<DoctorAppoinment/>}
      />  
      <Route
       path="/BuyMedicine"
       element={<BuyMedicine/>}
      ><Route path=":forwardPath" element={<BuyMedicine/>}>
        <Route path=":uuid" element={<BuyMedicine/>}></Route>
        </Route></Route>
       <Route
       path="/Lab"
       element={<Lab/>}
      />
      <Route
      path='/FamilyDoctor'
      element={<FamilyDoctor/>}/>
      <Route
       path='/5'
       element={<Test1/>}/>
       <Route
         path='/88'
         element={<PayementGateway/>}
       />
       {/* <Route
         path='/loginPartner'
         element={<Partnerlogin/>}
       /> */}
       {/* <Route
         path='/map'
         element={<Map/>}
       /> */}
       <Route
         path='/loginDeliveryBoys'
         element={<DeliveryBoysLogin/>}
       />
        <Route
         path='/DoctorDashboard'
         element={<DoctorDashboard/>}
       />
       <Route
         path='/MedicalShop/:forwardPath'
         element={<MedicalShop showSideBar={showSideBar} close={onCLickIconHandler}/>}>
         </Route>
         <Route
         path='/LabPortal'
         element={<LabPortal showSideBar={showSideBar} close={onCLickIconHandler}/>}>
         </Route> 

       <Route
      path="*"
      element={
        // <main style={{ padding: "1rem" }}>
        //   {/* <p>There's nothing here!</p>
        //   <button onClick={onClickMe}>click me</button> */}

        // </main>
        <MyComponent/>
      }/>
      <Route path="/doctor-list/:category" element={<DoctorList />} />
      <Route path="/doctor-details/:doctorId" element={<DoctorDetails />} />
    </Routes>
    </div>
  );
}

export default App;