import './SideBar.css';
import { NavLink} from 'react-router-dom';
import React from 'react';

const SideBar = (props) => {
  console.log(props.menu,"****")
const onClickHandler = () => {
  console.log("clicked");}
return(
    <div id="container">
            <div className="backdrop" style={{display:props.show ? 'block' :'none'}} onClick={() => props.close()}></div> 
            <div id={`${props.show ? "lgMenu123": "lgMenu"}`}>
              <span id="exit" onClick={props.close}>&times;</span>
                <ul>
                  {/* <li className='li121' onClick={onClickHandler}><NavLink to='/login' className='navlink0001'>Login</NavLink></li>
                  <li className='li121'>Instant Service</li>
                  <li className='li121'>Book Appoinment</li>
                  <li className='li121'>DigitalMarketing</li>
                  <li className='li121'>Premium</li>
                  <li className='li121'>My mind space</li>
                  <li className='li121' onClick={onClickHandler}><NavLink to='/DoctorDashboard' className='navlink0001'>Doctor Login</NavLink></li> */}
                  {props.menu.map((e) => <li key={e.name} className='li121' onClick={onClickHandler}><NavLink to={e.link} className='navlink0001'>{e.name}</NavLink></li>)}
                </ul>
            </div>
    </div>
)};

export default SideBar;
