import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import React, { useEffect, useRef, useState } from 'react';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/styles.css';
import "react-multi-carousel/lib/styles.css";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from 'react-redux';
import { AuthAction } from '../../Store/Store';
import HealthLocker from './AfterLogin/HealthLocker';
import MyOrders from './AfterLogin/MyOrders';
import Packages from './AfterLogin/Packages';
import UserHome from './AfterLogin/UserHome';
import styles from './Login.module.css';
/*import Input from '@mui/material/Input';*/
import { PostAPI } from '../ApiModules/ApiInterFace';
import MainHeader from '../MainHeader';
import Input from '../UI/Input';
import Select from '../UI/Select';
import UserProfile from './UserProfile/UserProfile';
import img0001 from '../../Assets/bg1.png';

const doctorsinfo = [
   {id:1, type:  "COVID Consultation",availableTime: "9AM -10PM",marketPrice:"600",offerdPrice:"400"},
   {id:2, type:  "Consultatnt Physician",availableTime: "9AM -11PM",marketPrice:"600",offerdPrice:"400"},
   {id:3, type:  "Gynecology",availableTime: "9AM -5PM",marketPrice:"1000",offerdPrice:"888"},
   {id:4, type:  "Pediatrics",availableTime: "9AM -8PM",marketPrice:"300",offerdPrice:"250"},
   {id:5, type:  "Dermatology",availableTime: "9AM -7PM",marketPrice:"390",offerdPrice:"190"},
   {id:6, type:  "ENT",availableTime: "9AM -9PM",marketPrice:"340",offerdPrice:"240"},
   {id:7, type:  "Nutrition Counselling",availableTime: "9AM -9PM",marketPrice:"500",offerdPrice:"250"},
   {id:8, type:  "Ortho pedics",availableTime: "9AM -6PM",marketPrice:"400",offerdPrice:"300"},
   {id:9, type:  "psycology",availableTime: "9AM -7PM",marketPrice:"700",offerdPrice:"500"},
   {id:10, type:  "pysicatry",availableTime: "9AM -8PM",marketPrice:"6000",offerdPrice:"1500"},
   {id:11, type:  "cardiology",availableTime: "9AM -9.40PM",marketPrice:"3000",offerdPrice:"1000"},
   {id:12, type:  "eurology",availableTime: "9AM -9.55PM",marketPrice:"570",offerdPrice:"500"},
]; 


                  

const QuestionAnswers = [
   {question: "Are the specialists available 24/7?", 
    answer:"Our specialists are available for consultation from 9AM to 9PM on all the 7 days of the week"},
   {question:"It says 'All doctors are busy now'. When will I get a slot for consultation? How long should I wait before trying again?",
   answer:"ou might be getting this message because all our doctors are busy at the moment. Try starting a chat in another 10 mins"},
   {question:"What are the qualifications of the specialist?",
   answer:"All specialists on the Tata Health platform are highly qualified and experienced physicians with a minimum qualification of M.D. (Doctor of Medicine) in their chosen field of specialisation, in addition to their MBBS degree.You can see their details (Name, experience, degrees) before you make the payment for the specialist consultation"}, 
   {question:"I consulted with Dr. XYZ 5 days ago. Can I connect with him/her again for a follow up? What will be the consultation fee?",
    answer: "You are entitled to one free follow-up consultation with a specialist doctor within 7 days of your first consultation. We will connect you to the same specialist depending on slot availability."},
    {question:"Payment not reflected in TDH App? (But reflecting in my bank account)?",
    answer: "It may take a while for the payment to reflect in the app. If there is no progress, reach out to our customer care team on care@tatahealth.com."},
    {question:"Payment Failure | Repeated Payment Failure",
    answer: "Please re-initiate the payment in 10 minutes. In case of repeated failures, you can reach out to customer care and they will assist you."},
    {question:"I missed my appointment. What should I do now?",
    answer: "You must initiate another chat via Insta Doc and mention that you missed your appointment. We will help you book an appointment on the next available time slot. You will receive a coupon code which will allow you to reschedule for free."},
    {question:"Lost internet connectivity mid chat. What happens to my payment? Can I continue the chat later?",
    answer: "Yes you can continue the chat (given you regain connectivity in the appointment window, else you can initiate a new chat). We will help you connect to a specialist without any extra cost."}
] 

const Login = () => {

  const Authstate =  useSelector(state => state.auth);
  const Auth  =  Authstate.isAuthenticated;
  console.log(Authstate,"555555")
  let display = "Us";



  const [show,setShow] = useState("notlogin");
  // const [show,setShow] = useState('login');

  var initState = null;
  if (Auth) {
    console.log( Authstate['user']['firstname'])
    if (show === "notlogin") 
      {setShow('UserPrfoile')} 
  display  = Authstate['user']['firstname'].slice(0,1).toUpperCase() + Authstate['user']['lastname'].slice(0,1).toUpperCase();
  }else{
   initState = "notlogin"
  }  



  const Ref = useRef(null);
  const [phoneNumber,setPhoneNumber] = useState(null)
  const [timer, setTimer] = useState('00:00:00');
  const [pin, setPin] = useState(null);
 const dispatch = useDispatch();

  const handleChangePin  = (code) => {
   if(code == +'0123'){
       console.log('verified')
       setShow('Home')
       dispatch(AuthAction.login()) 
       return(setPin(null))
   }else{return(setPin(code))}
};


const onChangePhoneNumber = (value) => {
   console.log(value,"444444")
   setPhoneNumber(value);
};
const getTimeRemaining = (e) => {
   console.log(Date.parse(e),Date.parse(new Date()),"ooo");
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
        total, hours, minutes, seconds
    };
}

const startTimer = (e) => {
   let { total, hours, minutes, seconds }       
               = getTimeRemaining(e);
   if (total >= 0) {

       // update the timer
       // check if less than 10 then we need to 
       // add '0' at the beginning of the variable
       setTimer(
           (hours > 9 ? hours : '0' + hours) + ':' +
           (minutes > 9 ? minutes : '0' + minutes) + ':'
           + (seconds > 9 ? seconds : '0' + seconds)
       )
   }else{
      clearInterval(Ref.current)
   }

}



 useEffect(() => {
  // clearTimer(getDeadTime());
  }, []);


const clearTimer = (e) => {

// If you adjust it you should also need to
// adjust the Endtime formula we are about
// to code next    
setTimer('00:00:30');

// If you try to remove this line the 
// updating of timer Variable will be
// after 1000ms or 1sec
if (Ref.current) clearInterval(Ref.current);
const id = setInterval(() => {
    startTimer(e);
}, 1000)
Ref.current = id;
};


const onClickReset = () => {
clearTimer(getDeadTime());
}
const getDeadTime = () => {
   let deadline = new Date();
   console.log(deadline,deadline.getSeconds(),"ll")

   // This is where you need to adjust if 
   // you entend to add more time
   deadline.setSeconds(deadline.getSeconds() + 30);
   return deadline;
}

console.log(show === 'UserPrfoile')


const initValue  = {
   firstname: '',
   lastname: '',
   password: '',
   confirmPassword: '',
   email:'',
   age:'',
   gender:'',
   country:'',
   state:'',
   showPassword: false,
   showCPassword: false,
 };

const [values, setValues] = useState(initValue);

 const handleChange = (prop) => (event) => {
   setValues({ ...values, [prop]: event.target.value });
   console.log(values, '**')
 };

 const handleClickShowPassword = () => {
   setValues({
     ...values,
     showPassword: !values.showPassword,
   });
 };

 const handleMouseDownPassword = (event) => {
   event.preventDefault();
 };

 const handleClickShowCPassword = () => {
   setValues({
     ...values,
     showCPassword: !values.showCPassword,
   });
 };

 const handleMouseDownCPassword = (event) => {
   event.preventDefault();
 };


 const onLoginOutput = (Response) => {
   console.log(Response,"got the result")
   if (Response.outcome === "success"){
    localStorage.setItem("beekey-user",Response["beekey-user"])
      dispatch(AuthAction.login(Response))
      setShow('MyOrder') 
   }else{
     console.log(Response.message,"error")  
   }}
  
 const onSignupOutput = (Response) => {
    console.log(Response,"got the result")
    if (Response.outcome === "success"){
       setShow("login")
    }else{
      console.log(Response.message)  
    }}

function login(){
      console.log("calledlogin")
      PostAPI({path:"/login/user"
               ,body:JSON.stringify({email:values.email,password:values.password})
               ,type:'application/json'
               ,callbackfunc:onLoginOutput
             });
  setValues(initValue)
  }

function signupuser(){
    PostAPI({path:"/signupUser"
             ,body:JSON.stringify(values)
             ,type:'application/json'
             ,callbackfunc:onSignupOutput
           });
setValues(initValue)
}

console.log("****",show);
return(
    <div className={styles.main3231}>
         <MainHeader/>
     
      <div className='profile-section'>
         
         {show ==='login' && <div>
         <div className={styles.div0001}>
          <img className={styles.img0001} src={img0001}/>
         </div>
         <div className={styles.div00021}>
          <div>
         <p className={styles.para0001a}>User Login </p>
         <p className={styles.para0002a}>Certified doctors, home delivered medicines, NABL accredited labs and more</p>
            <p className={styles.para0003a}>By Clicking on the "Continue" button or Social Profiles,
             you agree to our <span className={styles.span0001}> Privacy Policy </span> &  <span className={styles.span0001}>Terms of Use </span></p> 
             </div>   
         <Input  status='success' type="text"  value={values.email} label='Email*' onChange={handleChange('email')}></Input>
         <Input  status='success' type="password" value={values.password} label='password*' onChange={handleChange('password')}></Input>
         <div className={styles.div00022}>
         <Button variant="outlined" onClick={() => { console.log(values);setShow("notlogin");}}>Cancel</Button>
         <Button variant="contained" onClick={() => { console.log(values);login()}}>Login</Button>
         </div>
            </div>
          </div>}
         {show === 'signup' && <div>
         <div className={styles.div0001}>
          <img className={styles.img0001} src={img0001}/>
         </div>
         <div className={styles.div00021}>
          <div>
            <p className={styles.para0001a}>Welcome to a healthier life</p>
             {/* <p className={styles.para0002a}>Certified doctors, home delivered medicines, NABL accredited labs and more</p>  */}
              <p className={styles.para0003a}>By Clicking on the "Continue" button or Social Profiles,
              you agree to our <span className={styles.span0001}> Privacy Policy </span> &  <span className={styles.span0001}>Terms of Use </span></p> 
              
          </div>
         <Input status='success' type="text" value={values.username} label='firstname*' onChange={handleChange('firstname')}></Input>
         <Input status='success' type="text" value={values.username} label='lastname*' onChange={handleChange('lastname')}></Input>    
         <Input  status='success' type="text"  value={values.email} label='Email*' onChange={handleChange('email')}></Input>
         <Input  status='success' type="password" value={values.password} label='password*' onChange={handleChange('password')}></Input>
         <Input  status='success' type="password"  value={values.confirmPassword} label='Confirm Password*' onChange={handleChange('confirmPassword')}></Input>
         <div className = {styles.div00024}>
         <Input  status='success' type="number" className ={styles.input0003} value={values.age} label='Age*' onChange={handleChange('age')}></Input>
         <Select status='success' className ={styles.input0003} options ={[{key:"male",value:"male"},{key:"female",value:"female"},{key:"other",value:"other"}]} label="Gender" onChange={handleChange('gender')}/>
         </div>
         <Select status='success' options ={[{key:"india",value:"INDIA"},{key:"other",value:"Other"}]} label="Country" onChange={handleChange('country')}/>
          <div className={styles.div00022}>
         <Button variant="outlined" onClick={() => { console.log(values); setShow("notlogin")}}>Cancel</Button>
         <Button variant="contained" onClick={() => { console.log(values); signupuser()}}>Signup</Button>
         </div>
       </div>
         </div>} 
         {show === 'notlogin' &&
         <div>
          <div className={styles.div0001}>
            <img className={styles.img0001} src={img0001}/>
          </div>
         <div className={styles.div00021}>
          <div>

         <p className={styles.para0001a}>Welcome to a healthier life</p>
             <p className={styles.para0002a}>Certified doctors, home delivered medicines, NABL accredited labs and more</p>
            <p className={styles.para0003a}>By Clicking on the "Continue" button or Social Profiles,
             you agree to our <span className={styles.span0001}> Privacy Policy </span> &  <span className={styles.span0001}>Terms of Use </span></p> 
             </div>
         <div className={styles.div00023}> 
         <Button variant="outlined" onClick={() => { console.log(values); setShow("signup")}}>Signup</Button>
         <Button variant="contained" onClick={() => { console.log(values); setShow("login")}}>Login</Button>
         </div> 
            </div>
            </div>}
         {show === 'authmobile' &&
       <div>
        <div className={styles.div0001}>
          <img className={styles.img0001} src={img0001}/>
        </div>
          <div className={styles.div0002}>
            <p className={styles.para0001a}>Welcome to a healthier life</p>
            {/* <p className={styles.para0002a}>Certified doctors, home delivered medicines, NABL accredited labs and more</p> */}
            <p className={styles.para0003a}>By Clicking on the "Continue" button or Social Profiles,
             you agree to our <span className={styles.span0001}> Privacy Policy </span> &  <span className={styles.span0001}>Terms of Use </span></p>
             
             <div className={styles.div0003}>
                <div  className={styles.div0004}>+91</div>
                <input className={styles.input0001} type="tel"  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" placeholder='Enter Mobile Number' value={phoneNumber} onChange={(event => onChangePhoneNumber(event.value))}></input>
             </div>
             <button 
             
             className={styles.button0001} 
             disabled={ phoneNumber === null ? true: false} 
             style={{cursor:  phoneNumber === null? 'not-allowed': 'pointer',
             backgroundColor:phoneNumber === null? '#d2e2e6': 'rgb(94, 190, 65)',
              color:phoneNumber === null? '#808081': 'white' }}
             onClick ={() => setShow('Otp')}
             ><p>CONTINUE</p></button>
             <div className={styles.div0006}>
               <div style={{display:'flex', alignItems:"center"}}>
               <p className={styles.para0004a}>Use social profiles instead</p>
               <div style={{marginLeft:'20px', backgroundColor:'rgba(10,10,10,0.1)',cursor:'pointer', borderRadius:'3px'}} onClick={() =>  { setShow("Home"); dispatch(AuthAction.login());} }>{'Skip >>>'}</div>
               </div>
               <div className={styles.div0007}>
                <p>facebook</p>
                <p>twitter</p>
                <p>Google</p>
               </div>
             </div>
         </div>   
        </div>
        }
        {show === 'Otp' && 
                 <div className={styles.div0002}>
            <p className={styles.para0005a}> Enter OTP</p>
            <div  className={styles.div0008}>
              <p className={styles.para0002a}>Please enter the OTP sent to +919072511185</p>
              <FontAwesomeIcon icon={solid("pencil")}  color="rgb(94, 190, 65)" />
            </div>
            <OtpInput
               value={pin}
               onChange={handleChangePin}
               numInputs={4}
               separator={<span style={{ width: "8px" }}></span>}
               isInputNum={true}
               shouldAutoFocus={true}
               isInputSecure={true}
               inputStyle={{
               border: "1px solid transparent",
               borderRadius: "8px",
               width: "54px",
               height: "54px",
               fontSize: "12px",
               color: "#000",
               fontWeight: "400",
               caretColor: "blue",
               placeholder:"*",
               margin:"70%",
               border: "1px solid #CFD3DB"
               }}
               focusStyle={{
                 border: "1px solid blue",
                 outline: "none"
               }}
            />
            <div className={styles.div0011}>
              <h2>{timer}</h2>
              <button onClick={onClickReset}>Reset</button>
            </div>
         </div> }
         {show === 'UserPrfoile' && <UserProfile/>}
         {show === 'Home'&& <UserHome/> }
         {show === 'Packages' && <Packages/> }
         {show === 'MyOrder' && <MyOrders/> }
         {show === 'HealthLocker' && <HealthLocker/>}
        
        { Auth && <div className={styles.button003}>
            <div className={styles.div0015}  onClick={() =>{console.log("setting show");setShow('Home')}}><FontAwesomeIcon icon= {solid("house")} 
             className={styles.icon001}  
             color={show === 'Home'? '#000000': 'white'} onClick={() =>{console.log("setting show");setShow('Home')}}/>
            <p className={styles.para009} style={{color:show === 'Home'? '#ffffff': 'white'}}  >Home</p>
            </div>
             <div className={styles.div0015} onClick={() =>{console.log("setting show");setShow('Packages')}} > <FontAwesomeIcon icon= {solid("briefcase")}  className={styles.icon001} color={show === 'Packages'? '#000000': 'white'} onClick={() =>{console.log("setting show");setShow('Packages')}}/>
             <p className={styles.para009} style={{color:show === 'Packages'? '#ffffff': 'white'}}  >Packages</p>
             </div>
             <div className={styles.div0015} onClick={() =>{console.log("setting show");setShow('UserPrfoile')}}><div className={styles.div0114}><div className={styles.div0115}>{display}</div></div>
             <p className={styles.para009} >sanadca</p>
             </div>
             <div className={styles.div0015}  onClick={() =>{console.log("setting show");setShow('HealthLocker')}} ><FontAwesomeIcon icon={solid("vault")} className={styles.icon001} color={show === 'HealthLocker'? '#000000': 'white'} onClick={() =>{console.log("setting show");setShow('HealthLocker')}}/>
             <p className={styles.para009} style={{color:show === 'HealthLocker'? '#ffffff': 'white'}}  >Health Locker</p>
             </div>
             <div className={styles.div0015}   onClick={() =>{console.log("setting show");setShow('MyOrder')}}><FontAwesomeIcon icon={solid("box-archive")} className={styles.icon001} color={show === 'MyOrder'? '#000000': 'white'}   onClick={() =>{console.log("setting show");setShow('MyOrder')}}/>
             <p className={styles.para009} style={{color:show === 'MyOrder' ? '#ffffff': 'white'}}  onClick={() =>{console.log("setting show");setShow('MyOrder')}}>My oders</p>
             </div>
         </div> }
        
      </div>
    </div>
    )
};

export default Login;