import styles from './BeekeyInfo.module.css';
import fb from '../../Assets/facebook.png';
import insta from '../../Assets/insta.webp';
import twitter from '../../Assets/twitter.png';
import linked from  '../../Assets/linkedin.png';
import React from 'react';

const BeekeyInfo = () => { 

    return(
     <div style={{display:"auto",flexDirection:"column"}}>   
     <div className={styles.fullbody}>
            <div className={styles.infopacket}>
                <div>Branches</div>  
              <ul style={{marginTop:"30px"}}>
                  <li className={styles.li}>Perinthalmanna</li>
                  <li className={styles.li}>Kochi</li>
                  <li className={styles.li}>Palakkad</li>
                  <li className={styles.li}>Dubai</li>
            </ul>
            </div>
            <div className={styles.infopacket}>
                <div>Quik Links</div>
                <ul style={{marginTop:"30px"}}>
                    <li className={styles.li}>Work with us</li>
                    <li className={styles.li}>Find Doctor</li>
                    <li className={styles.li}>Find Medicine</li>
                    <li className={styles.li}>Book Leboratory</li>
                    <li className={styles.li}>Family Doctor</li>
                    <li className={styles.li}>Career</li>
                </ul>
            </div>
            <div className={styles.infopacket}>
               <div>Help</div>
                <ul style={{marginTop:"30px"}}>
                    <li className={styles.li}>General</li>
                    <li className={styles.li}>Ask your doubt</li>
                    <li className={styles.li}>Chat with Medical officer</li>
                    <li className={styles.li}>Test enquiry</li>
                </ul>
               
            </div>
        </div>
            <div  className={styles.imgdiv}>
                <img  src={fb}  className={styles.img123} alt="connect"/>
                <img  src={twitter} className={styles.img123}  alt="connect" />
                <img  src={insta} className={styles.img123}  alt="connect"/>
                <img  src={linked} className={styles.img123}  alt="connect"/>
            </div>
        </div>
    )

};

export default BeekeyInfo;