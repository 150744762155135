import styles from './MainHeader.module.css';
import BeekeyImage from '../Assets/bm-icon-3.png';
import Download from '../Assets/download.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'; 
import { Link } from 'react-router-dom';
import React from 'react';

const MainHeader  = (props) => {
    return(
        <div className={styles.header} >
            <div className={styles.naviconlogo}>
            <FontAwesomeIcon icon={solid("align-right")} size="2x" className={styles.iconHeader} onClick={props.iconClicked}/>
            <div className={styles.LogoEnvlope}>
                <img src={BeekeyImage} alt="Beekey Logo"  className={styles.beekeylogo}/>
             <div className={styles.logofont}>
                Beekey <br/>Medics 
             </div>
            </div>
            </div>
            <div className={styles.navdiv}>
            <div> Coustomer Support</div>
            <div className={styles.download}>
                <img src={Download} alt="Download Logo" className={styles.downloadImg}/>
                <div>Download App</div>
            </div>    
            <Link to="/premiumCoustomer" className={styles.link}><div className={styles.permiumdiv }>Call Doctor</div></Link>
            </div>
        </div>
    )};

export default MainHeader;




