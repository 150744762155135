import styles from './MedicineOrder.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';

const MedicineOrder = (props) => {
     
const exactOrder = props.orders;
console.log(exactOrder,"ooooo")

var timestamp = exactOrder["a_ctime"];
var date = new Date(timestamp);

    return(
<div className={props.selectedOrder.ordernumber === exactOrder["uuid"] ?  styles.div0022a : styles.div0022} onClick ={() => props.selectOrder(exactOrder)}>
  <div className={styles.div0024}>
  <div className={styles.div0023}>
    <div>{exactOrder["uuid"]}</div>
    <div className={styles.div0023a}>
     <FontAwesomeIcon icon={solid("clock")} className={styles.icon002}/>
     <p>{+ date.getHours() + ":" +date.getMinutes() }</p>
    </div>  
    </div>  
    <p className={styles.para0001}>placed a minutes ago</p>
  </div >
    <div className={styles.div0024}><p>{exactOrder["user"]["firstname"]}
    {exactOrder["user"]["lastname"]}</p>
    <p className={styles.para0004}>palakkad</p></div>
    <div className={styles.div0024}>
      Total Amount = {exactOrder["final_amount"]}
    </div>
    <span className={styles.span003}>
      <FontAwesomeIcon icon={solid("person-biking")} className={styles.icon002}/>
      <p className={styles.para0002}>Driver arrives in</p><p className={styles.para0002}>13 mins</p>     
    </span>
</div> )};


export default MedicineOrder;