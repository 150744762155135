
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import styles from './Dashboard.module.css';
import "./PersonalSection.css";
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import DocIcon from '../../Assets/docicon.jpg';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import { Card, CardContent, Typography, Button, TextField } from '@mui/material';
import { useSelector } from 'react-redux';





function PersonalSection() {
  // const [selectedValue, setSelectedValue] = React.useState('a');
  // const [age, setAge] = React.useState('');
  const authState = useSelector(state => state.auth);
  const [editing,setEditing] = useState(false);
  const [storeData, setstoreData] = useState({
    name: 'Neethi Medicals',
    category: 'Medical Shop',
    // specialization: 'Internal Medicine',
    location: '123 Main St, Anytown, USA',
    phone: '123-456-7890',
    email: 'johndoe@example.com',
  });

  const handleEdit  =() =>{
    setEditing(true)
  };
  const handleChange = (event) =>{
    const {name,value} = event.target;
    setstoreData((prevData) =>({
      ...prevData,
      [name]:value,
    }))
  }
  const handleSave = (event) =>{
    event.preventDefault();
    setEditing(false)
  }


return(
  <Card>
    <CardContent>
      {!editing?(
        <>
        {/* <img src={authState.user.picture} alt=""/> */}
        <img src={DocIcon} alt=""/>
          <p>{storeData.name}</p>
        <button onClick={handleEdit}>Click</button>
        </>
        
      ):(
      <form onSubmit={handleSave}>
        <input label="Name" type="text" name="name" value={storeData.name} onChange={handleChange}/>
        <button type="submit">set</button>
      </form>
        
      )}
    </CardContent>
  </Card>

)

}
export default PersonalSection;


//     return <div className="container001">
 
//     <div className="form">
//       <div className="form-body">
//         <div className="username">
//           <label className="form__label" for="firstName">
//             First Name{" "}
//           </label>
//             <Box  sx={{ }} >
//             <TextField  style={{width:"200px"}} id="email" label="Firstname" name='email' onChavariant="outlined"  />
//             </Box> 
//         </div>
//         <div className="username">
//           <label className="form__label" for="lastName">
//             Last Name{" "}
//           </label>
//           <Box  sx={{ }} >
//             <TextField  style={{width:"200px"}} id="email" label="Lastname" name='email' onChavariant="outlined"  />
//             </Box>
//         </div>
//         <div className="username">
//           <label className="form__label" for="gender">
//             gender{" "}
//           </label>
//           male
//           <input
//             type="radio"
//             name="male"
//             id="male"
//             className="form__input"
//             placeholder="male"
//           />
//           female
//           <input
//             type="radio"
//             name="male"
//             id="male"
//             className="form__input"
//             placeholder="male"
//           />
//         </div>
//         <div className="username">
//           <label className="form__label" for="gender">
//             certificates
//           </label>
//           <input class="custom-file-input" type="file"></input>
//         </div>
//         <div className="username">
//           <label className="form__label" for="firstName">
//             Age{" "}
//           </label>
//             <Box  sx={{ }} >
//             <TextField  style={{width:"200px"}} id="age" label="Age" name='email' onChavariant="outlined"  />
//             </Box> 
//         </div>
//         <div className="username">
//           <label className="form__label" for="firstName">
//             Category{" "}
//           </label>
//           <FormControl sx={{ m: 1, minWidth: 180, color:"black" }}>
//           <InputLabel id="demo-simple-select-label">Category</InputLabel>
//           <Select
//              id="demo-simple-select-helper"    
//              labelId="demo-simple-select-helper-label"
//              label="Category"
            
//         >
//           <MenuItem value="">
//             <em>None</em>
//           </MenuItem>
//           <MenuItem value='dentist'>Dentist</MenuItem>
//           <MenuItem value='eye-doctor'>Eye Doctor</MenuItem>
//           <MenuItem value='cardiologist'>Cardiologist</MenuItem>
//         </Select>
//         </FormControl>
//         </div>
//         <div className="username">
//           <label className="form__label" for="email">
//             Email{" "}
//           </label>
//           <Box  sx={{ }} >
//             <TextField  style={{width:"200px"}} id="email" label="Email" name='email' onChavariant="outlined"  />
//             </Box> 
//         </div>
//         <div className="username">
//           <label className="form__label" for="password">
//             Password{" "}
//           </label>
//           <Box  sx={{ }} >
//             <TextField  style={{width:"200px"}} id="email" name='email' onChavariant="outlined"   label="Password"
//           type="password"
//           autoComplete="current-password"/>
//             </Box> 
//         </div>
//         <div className="username">
//           <label className="form__label" for="confirmPassword">
//             Confirm Password{" "}
//           </label>
//           <Box  sx={{ }} >
//             <TextField  style={{width:"200px"}} id="email" name='email' onChavariant="outlined"   label="Password"
//           type="password"
//           autoComplete="current-password"/>
//             </Box> 
//         </div>
//       </div>
//       <div class="footer">
//         <button type="submit" class="btn">
//           Register
//         </button>
//       </div>
//     </div>

//     <div>
//       <img src={DocIcon} className="icon001"/>
//     </div>
    
// </div>;
 