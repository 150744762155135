// import beekeyimage from '../../Assets/beekey.jpeg';
import styles from './BeekeyGeneralinfo.module.css';
import React from 'react';
import BeekeyImage from '../../Assets/bm-icon-3.png';

const BeekeyGenaralInfo = () => {

return(
<div className={styles.fullbody}>

Beekey requests the general public and strongly recommends not to rely on any unauthenticated information. Please beware of false messages being circulated on social media under Beekey Medic’s brand name and logo. Beekey Medics requests you to consult any professional doctor or utilize InstaDoc, our online doctor chat service to clarify any health queries.
<div className={styles.part001}>
<div>
Beekey Medics Limited - PMNA Division<br/><br/>

<b>Registered Office</b><br/>
Perinthalmanna,Kerala,679322<br/><br/>

</div>
<div>
For queries and grievances, reach us at <a href="mailto:beekeymedics@gmail.com"><strong>beekeymedics@gmail.com</strong> </a>
</div>
<img src={BeekeyImage} alt="beekeylogo" className={styles.img001}/>
</div>
</div>)


};

export default BeekeyGenaralInfo;
