import useScript from './useScript';
import React from 'react';

const MyComponent = props => {
    useScript("https://apps.elfsight.com/p/platform.js");
    return(<div className="elfsight-app-476ba0fb-4c63-489c-bbf5-6edabb485607"></div>)
    // rest of your component
  };

export default MyComponent;
