// DoctorDetails.js
import React, { useState, useEffect }  from 'react';
import { GetApI,PostAPI } from '../ApiModules/ApiInterFace';
import { useParams } from 'react-router-dom';
import DoctorCalendar from '../Doctor/DoctorCalendar';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import MainHeader from '../MainHeader';
import styles from './DoctorDetails.module.css';
// import BookingModal from '../Doctor/BookingModal';
import BookingModal from '../UI/BookingModal';
import SlotsModal from '../Doctor/SlotsModal';



const DoctorDetails = () => {
  // Get the doctor ID from the URL parameters
  const { doctorId } = useParams();

  // For demonstration purposes, you can fetch the doctor details from an API or use mock data
  const doctorDetails = {
    id: doctorId,
    name: 'Dr. John Doe',
    specialization: 'Cardiologist',
    availableTime: '9 AM - 5 PM',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla fringilla turpis at tincidunt dictum.',
    // Add more details as needed
  };
  const localizer = momentLocalizer(moment)
  // const doctorAvailableSlots = [
  //   // Example slots
  //   { id: 1, title: 'Available Slot 1', start: new Date(), end: new Date(), resource: {} },
  //   { id: 2, title: 'Available Slot 2', start: new Date(), end: new Date(), resource: {} },
  //   // Add more slots as needed
  // ];
  const [availableSlots, setavailableSlots] = useState([]);

  const [isModalOpen,setIsModalOpen]= useState(null);

  const [payementProceed, setPayementProceed] =useState(null);
//  -----------------------------------------------------------------------------------------------------------------------//  
useEffect(() => {
  console.log('trying to hit')
    GetApI({path:"/getconsults",callbackfunc:updateConsults}) 
},[]);



//  -------------------------------------------------API CALL BACKS -------------------------------------------------------//

const updateConsults = (Data) => {
  if (Data.outcome === "success"){
    const newconsult = Data.consults.map((e) =>{
      return (
        { id: e.uuid,
          title: e.title,
          start: new Date(e.start_time),
          end: new Date(e.end_time),
          resource: e.patient_details
        })
    }
    );
    setavailableSlots(newconsult); 
  }else{
    console.log("error in getting consults")
  }
};


const updateConsultAck = (Data) => {
  console.log("***********",Data)
  if (Data.outcome === "success"){
    const newconsult = 
      { id      : Data.consult.uuid,
        title   : Data.consult.title,
        start   : new Date(Data.consult.start_time),
        end     : new Date(Data.consult.end_time),
        resource: Data.consult.patient_details
      }
    setavailableSlots(prev => [...prev.filter(e => e.id !== newconsult.id), newconsult]); 
    const obj = {keyid:Data.consult.patient_details.key
      ,amount:Data.consult.final_amount
      ,order_id:Data.consult.patient_details.order_id
      ,username:Data.consult.user.firstname + " " + Data.consult.user.lastname
      ,useremail:Data.consult.user.email
      ,userphone:Data.consult.user.phone}
    setPayementProceed(obj)
  }else{
    console.log("error in getting consults")
  }
}; 

//---------------------------------------------------API CALLS -----------------------------------------------------------//

const handleBookingEnquiry = (patientDetails) => {
  console.log(patientDetails);
  let { "id": removedValue, ...newObject } = patientDetails;  
  PostAPI({path:`/update_customer_ack/${patientDetails["id"]}`
      ,body:JSON.stringify({patient_details : newObject,patient :'self'})
      ,type:'application/json'
    ,callbackfunc:updateConsultAck})};
//---------------------------------------------------------------------------------------------------------------------------//

const openModal= (e) =>{
  setIsModalOpen(e);
}
const closeModal= () =>{
  setIsModalOpen(null)
};

//---------------------------------------------------------------------------------------------------------------------------//

  return (
    <div className={styles.mainContainer}>
      <MainHeader/>
      
    <div className={styles.detailsContainer}>
      <h1>{doctorDetails.name}</h1>
      <p className={styles.detailsItem}>Specialization: {doctorDetails.specialization}</p>
      <p className={styles.detailsItem}>Available Time: {doctorDetails.availableTime}</p>
      <p className={styles.detailsItem}>{doctorDetails.description}</p>
      {/* Add more details or actions as needed */}


      {/* <DoctorCalendar availableSlots={doctorAvailableSlots} />   */}
      <BigCalendar
      localizer={localizer}
      events={availableSlots}
      step={15}
      selectable ={true}
      defaultView="month"
      onSelectEvent={(event)   => openModal(event) }  /*modal should be open*/
      onSelectDay={(event)   => console.log('%Selected Event:', event)}
      dayPropGetter={(e) => console.log("jii")}
      // slotPropGetter={(e) => checkDoctoravailableDate(e,15*60*1000)}
      eventPropGetter={(e) =>{return{className:(Object.keys(e.resource).length !== 0)?"booked_event":""}}}
      // onSelectSlot
      views={['month', 'week', 'day']}
      defaultDate={new Date()}
      startAccessor="start"
      endAccessor="end"
      popup={true}
      timeslots={1}
      style={{ height: '600px' }} 
      // onShowMore={(events, date) => this.setState({ showModal: true, events })}
     
    />
    {/* {isModalOpen && <BookingModal onClose={closeModal} availableSlots={availableSlots} booking={booking}/>} */}
    {isModalOpen !== null && <SlotsModal onClose={closeModal} slot={isModalOpen} submit={handleBookingEnquiry} proceed={payementProceed} />} 
    </div>
    </div>

  );
};

export default DoctorDetails;
