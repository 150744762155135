import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import img1 from '../../Assets/playstore.png';
import img2 from '../../Assets/appstore.png';
import style from './Contact.module.css';
import React from 'react';

const Contact = () => {
    return(
        <div className={style.fullbody}>
            <hr style={{width:"100vw",marginTop:"20px"}}/>

            <div  className={style.contact}>
                <FontAwesomeIcon icon={solid("headset")}  className={style.icon} size="x"/>
                <a href="tel:+9061713244">9061713244</a>
            </div>
            <div  className={style.contact}>
            <FontAwesomeIcon icon={solid("paper-plane")} className={style.icon} size="x"/>
                    <a href="mailto:beekeymedics@gmail.com">beekeymedics@gmail.com</a>
            </div>
            <div className={style.contact}>
                Download Karo
                <div style={{marginTop:"0"}}>
                    <img src={img1} style={{width:"130px",}} alt="google play store"></img>
                    <img src={img2} style={{width:"130px",}} alt="Apple play store"></img>
                </div>
            </div>
        </div>
    )
};

export default Contact;