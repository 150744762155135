import * as React from 'react';
import { useState,useEffect } from "react";
import usericon from'../../Assets/user-icon.png';
import earningbg from'../../Assets/earningbg.svg';
import styles from'./EarningSection.module.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { PostAPI } from '../ApiModules/ApiInterFace';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

const EarningSection = ({ earningsData = [] }) => {
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [isWithdrawalPending, setIsWithdrawalPending] = useState(false);

  const handleWithdrawal = () => {
    setIsWithdrawalPending(true);
    // call API to process withdrawal here
    setTimeout(() => {
      setIsWithdrawalPending(false);
      setWithdrawalAmount(0);
      alert('Withdrawal processed successfully!');
    }, 2000);
  };
  const [activeConsult, setActiveConsult] = useState([]);
    
console.log(activeConsult)
    const onGetActiveConsultsInfo = (Response) => {
        // setLoading(false);
        console.log(Response,"got the result")
        if (Response.outcome === "success"){
            const ModifiedResult =  Response.result.map((e,i) =>
                 {return({id:i,
                          firstName:e.user.firstname,
                          lastName:e.user.lastname,
                          phone:e.user.phone,
                          age:e.user.age,
                          email:e.user.email,

                })}) 
            setActiveConsult(ModifiedResult)
        }else{
          console.log(Response.reason,"error")  
        }}
    
        
      const getActiveConsults = async () => {
        // setLoading(true)
        PostAPI({path:"/doctor/consults"
                 ,body:JSON.stringify({status:'booked'})
                 ,type:'application/json'
                 ,callbackfunc:onGetActiveConsultsInfo
               });
    }
    
    useEffect(() => {
        getActiveConsults() ;
    
    },[])
    
   
    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'firstName',
          headerName: 'First name',
          width: 150,
          editable: true,
        },
        {
          field: 'lastName',
          headerName: 'Last name',
          width: 150,
          editable: true,
        },
        {
          field: 'age',
          headerName: 'Age',
          type: 'number',
          width: 50,
          editable: true,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 110,
            editable: true,
          },
          {
            field: 'email',
            headerName: 'Email',
            width: 220,
            editable: true,
          },

        ];

  return (
    <div className={styles.dashboard}>
      <div className={styles.totalearnings}>
        {/* <h2>Payment Information</h2> */}
        <div className={styles['user-icon']}></div>
        <p>Welcome back, John Doe!</p>
        <p>Your account balance is <strong>$10,000</strong></p>
        <img src={earningbg} alt=""/>
      </div>


      <div className={styles.dashboardsection}>
        <div className={styles.withdraw} >
          <h4 style={{background:"black",color:"white",padding:"10px 24px",marginBottom:"20px",borderRadius:"0 20px 20px 0",width:"fit-content"}}>Withdraw</h4>

          <div className={styles.withdrawform}>
          <p>Withdrawal Amount</p>
            <input type="number" value={withdrawalAmount} min="50" onChange={(e) => setWithdrawalAmount(Number(e.target.value))} className={styles['withdrawal-input']} />
            <button disabled={isWithdrawalPending || withdrawalAmount < 50} onClick={handleWithdrawal} className={styles['withdrawal-button']}>{isWithdrawalPending ? 'Processing...' : 'Withdraw'}</button>
          </div>
          <p>*Minimum withdrawal amount: $50</p>
          <p>*Processing time: 1-2 business days</p>
        </div>
        <div className={styles.withdraw} >
        <h4 style={{background:"black",color:"white",padding:"10px 24px",marginBottom:"50px",borderRadius:"0 20px 20px 0",width:"fit-content"}}>
          Earning History
          </h4>
          </div>
       
      </div>
      
      <div className={styles.dashboardsection}>
      <LineChart className={styles.chart} width={500} height={300} data={earningsData}>
          <XAxis dataKey="date" />
          <YAxis />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="amount" stroke="#d40000" />
        </LineChart>

        
      <div className={styles.earningtable}>
        <h3 style={{fontSize:"auto",textDecoration:"none",margin:"auto",textAlign:"center"}}>Details</h3>
         
         <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={activeConsult}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              // disableRowSelectionOnClick
            />
          </Box>
        {/* <table >
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Source</th>
            </tr>
          </thead>
          <tbody>
            {earningsData.map((earning, index) => (
              <tr key={index}>
                <td>{earning.date}</td>
                <td>${earning.amount.toFixed(2)}</td>
                <td>{earning.source}</td>
              </tr>
            ))}
          </tbody>
        </table> */}
      
      </div>
      </div>
     
    </div>
  );
};
export default EarningSection;
