// DoctorAppointment.js
import React from 'react';
import styles from './DoctorAppoinment.module.css';
import Slider from 'react-slick';
import MainHeader from '../MainHeader';
import Footer from './Footer';
// import { Link } from 'react-router-dom';
import { Link, Route , Routes } from 'react-router-dom';
import DoctorList from './DoctorList';
import CardiologyIcon from '../../Assets/cardiology-icon.png';
import DermatologyIcon from '../../Assets/dermatology-icon.png';
import GynecologyIcon from '../../Assets/gynecology-icon.png';


const doctorsInfo = [
  { id: 1, type: "Cardiologist", description: "Specializes in heart-related issues", icon: CardiologyIcon },
  { id: 2, type: "Dermatologist", description: "Expert in skin-related problems", icon: DermatologyIcon },
  { id: 3, type: "Gynecologist", description: "Deals with women's reproductive health", icon: GynecologyIcon },
  { id: 4, type: "Cardiologist", description: "Specializes in heart-related issues", icon: CardiologyIcon },
  { id: 5, type: "Dermatologist", description: "Expert in skin-related problems", icon: DermatologyIcon },
  { id: 6, type: "Gynecologist", description: "Deals with women's reproductive health", icon: GynecologyIcon },
  // Add more categories as needed
];

const generalCategories = [
  { id: 1, type: "Offers", icon: null }, // Add your offer-related SVG icon
  { id: 2, type: "Other Category 1", icon: null }, // Add your SVG icon
  { id: 3, type: "Other Category 2", icon: null }, // Add your SVG icon
  // Add more general categories as needed
];

const FAQ = [
  { question: "How to book an appointment?", answer: "You can book an appointment through our online portal." },
  { question: "What are the consultation fees?", answer: "Consultation fees vary based on the doctor and service." },
  // Add more FAQs as needed
];

const DoctorAppointment = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={styles.main}>
<MainHeader />

    <div className={styles.container}>
      <h1>Find a Doctor</h1>

      {/* Doctors by Specialty */}
      <h2>Doctors by Specialty</h2>
      <div className={styles.doctorCategories}>
        {doctorsInfo.map((doctor) => (
          <div key={doctor.id} className={styles.doctorCategoryCard}>
            <img src={doctor.icon} alt={doctor.type} className={styles.categoryIcon} />
            <h3>{doctor.type}</h3>
            <p>{doctor.description}</p>
            <Link to={`/doctor-list/${doctor.type.toLowerCase()}`}>
                <button>See More</button>
              </Link>
          </div>
        ))}
      </div>

     


      {/* Offers Slider */}
      {/* <h2>Special Offers</h2> */}
      <Slider {...sliderSettings} className={styles.slider}>
        {generalCategories.map((category) => (
          <div key={category.id} className={styles.sliderItem}>
            <img src={category.icon} alt={category.type} className={styles.sliderIcon} />
            <h3>{category.type}</h3>
          </div>
        ))}
      </Slider>

      {/* General Categories */}
      <h2>General Categories</h2>
      <div className={styles.generalCategories}>
        {generalCategories.map((category) => (
          <div key={category.id} className={styles.generalCategoryCard}>
            <img src={category.icon} alt={category.type} className={styles.categoryIcon} />
            <h3>{category.type}</h3>
          </div>
        ))}
      </div>

      {/* FAQ Section */}
      <div className={styles.faqSection}>
        <h2>Frequently Asked Questions</h2>
        {FAQ.map((faq, index) => (
          <div key={index} className={styles.faqItem}>
            <h4>{faq.question}</h4>
            <p>{faq.answer}</p>
          </div>
        ))}
      </div>
  
    </div>
    
    <Footer/>
    </div>
  );
};

export default DoctorAppointment;
