import React, { useState } from 'react';

const UserStatus = () => {
  const [status, setStatus] = useState('active');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="user-status" style={{margin: "auto",background:"transparent",borderRadius:"10px",}}>
      <div className="dropdown">
        <button className="dropbtn" onClick={toggleDropdown}style={{width:"100px"}}>
          <span className={`dot ${status}`} />
          <span className="status-label">{status}</span>
        </button>
        {isDropdownOpen && (
          <div className="dropdown-content" >
            <ul style={{margin: "30px auto 30px auto"}}>
                <li>
                <label>
                    <input
                        type="radio"
                        value="active"
                        checked={status === 'active'}
                        onChange={handleStatusChange}
                    />
                    <span className="dot active" />
                    Active
                    </label>
                </li>
                <li>
                <label>
                <input
                    type="radio"
                    value="away"
                    checked={status === 'away'}
                    onChange={handleStatusChange}
                />
                <span className="dot away" />
                Away
                </label>
                </li>
                <li>
                <label>
                <input
                    type="radio"
                    value="offline"
                    checked={status === 'offline'}
                    onChange={handleStatusChange}
                />
                <span className="dot offline" />
                Offline
                </label>
                    
                </li>

            </ul>
            
            
            
          </div>
        )}
      </div>
    </div>
  );
};

export default UserStatus;
