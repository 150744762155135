import { useState, useEffect } from "react";
import styles from './MyOrders.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import img1 from '../../../Assets/activefile.webp';
import img2 from '../../../Assets/cancelfile.png';
import img3 from '../../../Assets/cancel.png';
import img4 from '../../../Assets/completed.png';
import img5 from '../../../Assets/correct.png';
import { GetApI } from "../../ApiModules/ApiInterFace";
import React from 'react';
const MyOrders = () => {

const [view, setView] = useState("Medicines");
const [viewStatus,setViewStatus]  = useState({displaytype:"All Orders",orders:[]})
const [modal,setModal] = useState("none")

console.log(viewStatus)

function getOrders(Output){
  console.log(Output ,"xvfdbedf")
  if (Output.outcome === "success"){
  setViewStatus((prev) => {return({...prev,orders:Output.orders})})
  }
};

useEffect(() => {
  const A = GetApI({path:"/getorders",callbackfunc:getOrders});
  console.log(A,"cfjgnvkbh")
},[]);


const orders =  viewStatus.orders.filter(e => {if(viewStatus.displaytype === "All Orders"){
  return true}
  else if(viewStatus.displaytype === "Cancelled Orders"){
    return e.status === "cancelled"
  }
  else if(viewStatus.displaytype === "Completed Orders"){
    return( e.status === "deliverd")
  }
  else{
    return(e.status !== "cancelled" || e.status === "deliverd")
  }
}).map(e => 
{ 
let file, statusDiv = null; 
if (e.status === "cancelled"){
  file = <img className={styles.img001} src={img2} alt="file"/>
  statusDiv  = <img className={styles.img002} src={img3} alt="cancel"/>}
  else if(e.status === "deliverd"){
    file = <img className={styles.img001} src={img4} alt="file"/>
    statusDiv  = <img className={styles.img002} src={img5} alt="cancel"/>
  }else {
    file = <img className={styles.img001} src={img1} alt="file"/>
    statusDiv  = <div className ={styles.htmlspinner}></div> 
  }

return(

<div className={styles.div0006}>
  <div  className={styles.div0003}>
    <div className={styles.div0002}>{file}</div>
    <div className={styles.div0004}>
      <div className={styles.para0001}>
        {e.status.toUpperCase()} 
        {statusDiv}
      </div> 
      <div className={styles.para0002}>{e.a_mtime}</div>
    </div>
  </div>
  <div className={styles.div0003a}>
    <div>
      <p className={styles.para0002}>ORDER ID</p>
      <p className={styles.para0002a}>{e.uuid}</p>
    </div>
    <div>
      <p className={styles.para0002}>ORDER PLACED ON</p>
      <p className={styles.para0002a}>{e.a_ctime}</p>
    </div>
  </div>
  <div className={styles.div0005}>View Order</div>
  <div className={styles.div0003a}>
    <button className={styles.button001}>View Order</button>
    <button className={styles.button003}>Cancel Order</button>
  </div>
</div>)
});


const ordefrs = 
<div>
<div className={styles.div0006}>
  <div  className={styles.div0003}>
    <div className={styles.div0002}><img className={styles.img001} src={img1} alt="file"/></div>
    <div className={styles.div0004}>
      <div className={styles.para0001}>Active Odrer  <div className ={styles.htmlspinner}></div> </div> 
      <div className={styles.para0002}>Sat, 06 Aug 2022, 08:33 PM</div>
    </div>
  </div>
  <div className={styles.div0003a}>
    <div>
      <p className={styles.para0002}>ORDER ID</p>
      <p className={styles.para0002a}>PHAR-2223-28094</p>
    </div>
    <div>
      <p className={styles.para0002}>ORDER PLACED ON</p>
      <p className={styles.para0002a}>Sat, 06 Aug 2022</p>
    </div>
  </div>
  <div className={styles.div0005}>View Order</div>
  <div className={styles.div0003a}>
    <button className={styles.button001}>View Order</button>
    <button className={styles.button003}>Cancel Order</button>
  </div>
</div>
<div className={styles.div0006c}>
  <div  className={styles.div0003}>
    <div className={styles.div0002c}><img className={styles.img001} src={img2} alt="file"/></div>
    <div className={styles.div0004}>
      <div className={styles.para0001c}>Cancelled Odrer <img className={styles.img002} src={img3} alt="cancel"/></div>
      <div className={styles.para0002}>Sat, 06 Aug 2022, 08:33 PM</div>
    </div>
  </div>
  <div className={styles.div0003a}>
    <div>
      <p className={styles.para0002}>ORDER ID</p>
      <p className={styles.para0002a}>PHAR-2223-28094</p>
    </div>
    <div>
      <p className={styles.para0002}>ORDER PLACED ON</p>
      <p className={styles.para0002a}>Sat, 06 Aug 2022</p>
    </div>
  </div>
  <div className={styles.div0005}>View Order</div>
  <div className={styles.div0003a}>
    <button className={styles.button001}>View Order</button>
    <button className={styles.button002}>Order Now</button>
  </div>
</div>;
<div className={styles.div0006c}>
  <div  className={styles.div0003}>
    <div className={styles.div0002c}><img className={styles.img001} src={img2} alt="file"/></div>
    <div className={styles.div0004}>
      <div className={styles.para0001c}>Cancelled Odrer <img className={styles.img002} src={img3} alt="cancel"/></div>
      <div className={styles.para0002}>Sat, 06 Aug 2022, 08:33 PM</div>
    </div>
  </div>
  <div className={styles.div0003a}>
    <div>
      <p className={styles.para0002}>ORDER ID</p>
      <p className={styles.para0002a}>PHAR-2223-28094</p>
    </div>
    <div>
      <p className={styles.para0002}>ORDER PLACED ON</p>
      <p className={styles.para0002a}>Sat, 06 Aug 2022</p>
    </div>
  </div>
  <div className={styles.div0005}>View Order</div>
  <div className={styles.div0003a}>
    <button className={styles.button001}>View Order</button>
    <button className={styles.button002}>Order Now</button>
  </div>
</div>;
<div className={styles.div0006c}>
  <div  className={styles.div0003}>
    <div className={styles.div0002d}><img className={styles.img001} src={img4} alt="file"/></div>
    <div className={styles.div0004}>
      <div className={styles.para0001d}>Completed Odrer <img className={styles.img002} src={img5} alt="cancel"/></div>
      <div className={styles.para0002}>Sat, 06 Aug 2022, 08:33 PM</div>
    </div>
  </div>
  <div className={styles.div0003a}>
    <div>
      <p className={styles.para0002}>ORDER ID</p>
      <p className={styles.para0002a}>PHAR-2223-28094</p>
    </div>
    <div>
      <p className={styles.para0002}>ORDER PLACED ON</p>
      <p className={styles.para0002a}>Sat, 06 Aug 2022</p>
    </div>
  </div>
  <div className={styles.div0005}>View Order</div>
  <div className={styles.div0003a}>
    <button className={styles.button001}>View Order</button>
    <button className={styles.button002}>Order Now</button>
  </div>
</div>;

</div>

 return( 
     <div>
      <div style={{display:modal}}>
        <div className={styles.backdrop} onClick={() => setModal('none')}></div>
       <div className={styles.modal}>
        <p onClick={() => setModal("none")} className={styles.header}>Filter</p>
         <p  onClick={() => setViewStatus(e => {return {...e, displaytype:"All Orders"}})}  className={viewStatus.displaytype === "All Orders" ? styles.activepara : null}>All Orders</p>
         <p  onClick={() => setViewStatus(e => {return {...e,displaytype:"Active Orders"}})}  className={viewStatus.displaytype === "Active Orders" ? styles.activepara : null}>Active Orders</p>
         <p  onClick={() => setViewStatus(e => {return {...e,displaytype:"Completed Orders"}})}  className={viewStatus.displaytype === "Completed Orders" ? styles.activepara : null}>Completed Orders</p>
         <p  onClick={() => setViewStatus(e => {return {...e,displaytype:"Cancelled Orders"}})}  className={viewStatus.displaytype === "Cancelled Orders" ? styles.activepara : null}>CancelOderes</p>
      </div> 
      </div>
     <div className={styles.div0016}>
     <div className={styles.div0017} onClick={() => setView('consultation')}> 
     <p    style={{color:view ==='consultation' ? 'rgb(70, 185, 70)': 'rgb(148, 147, 147)'}}>Consultation</p>
     </div>
     <div className={styles.div0017} onClick={() => setView("Medicines")}>
     <p   onClick={() => setView("Medicines")} style={{color:view ==='Medicines' ? 'rgb(70, 185, 70)': 'rgb(148, 147, 147)'}}>Medicines</p>
     </div>
     <div className={styles.div0017} onClick={() => setView('Lab Tests')}> 
     <p   style={{color:view ==='Lab Tests' ? 'rgb(70, 185, 70)': 'rgb(148, 147, 147)'}}>Lab Tests</p>
     </div>
   </div>
   <span   className={styles.span0002} style={{ transform: view === 'consultation' ? "translate3d(0px, 0px, 0px)": view === 'Medicines' ? "translate3d(129px, 0px, 0px)": "translate3d(258px, 0px, 0px)" }}></span>
   <div style={{display: "flex"}}>
    <p className={styles.para0011}>Showing</p>
     <div className={styles.para0011a} onClick={() => setModal('block')} >
      {viewStatus.displaytype}
    <FontAwesomeIcon icon={solid("angle-down")} className={styles.icon001} /></div>
   </div>
   <div>{orders}</div>
  </div>
 )
};


export default MyOrders