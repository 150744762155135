import img1 from '../../Assets/ai.jpg';
import img2 from '../../Assets/nano.webp';
import img3 from '../../Assets/hyper.jpeg';
import styles from './Article.module.css';
import React from 'react';

const Art = [{
    id:"001",
    img:img1,
    content:"Beauty of AI"
},
{
 id:"002",
 img:img2,
 content:"Nano make New World"
},
{
    id:"003",
    img:img3,
    content:"hyper:fure transport"
}];  

const Articles  = () =>  {
return(
    <div className={styles.articlebody}>
        <div className={styles.text001}>Technical App Articles</div>
        <div className={styles.text002}>Feel the future with Beekey</div>
        <div className={styles.articles}> 
        {Art.map((e) => {return(
            <div className={styles.shadowbox} key={e.id}>
                <img className={styles.imgstyle} src={e.img} alt="artciles"/>
                <div className={styles.text003}>{e.content}</div>
            </div>
        )})}
        </div>
        <div className={styles.button001}>ReadMore</div>
    </div>
)

};

export default Articles;
