import styles from './Input.module.css';
import { useState } from 'react';
import React from 'react';

const Select = (props) => {

    const [status,setStatus] = useState('success')
    const [selected,setSelected] = useState('null') 
    if(props.status !== status){
        setStatus(props.status)
    };
    const onChangeHandler = (event) => {
        const value = event.target.value;
        setSelected(value)
        props.onChange(event)}
    return(  
     <div className={ status === 'success' ? `${styles.inputcontainer} ${props.className}` : `${styles.inputfail} ${props.className} `}>
        <select 
        className={styles.select001}
        onChange={onChangeHandler}
        style={{padding:'10px',width:'100%', margin:0 , height:"90%" , border: "0.1px solid #ced0d6"}}
        >
            <option value='null'></option>
           {props.options.map((e) => <option value={e.value}>{e.key}</option>)}
        </select>
        <label className ={selected === 'null' ? styles.init : styles.enterd}>{props.label}</label>
     </div>)
};
export default Select;