import './Home.css';
import OfferList from './Offers/OfferList';
import Articles from './Offers/Articles';
import Footer from './Offers/Footer';
import MainHeader from './MainHeader';
import SideBar from './SideBar/SideBar';
import React from 'react';
import bg from '../Assets/doctors/bg1.png';
import Testing from './test';

const Home = (props) => {
 console.log(window,"ngchgvgjk")

 const menu = [{name:'login',link:'/login'},
               {name:'Instant Service',link:'/Instant Service'},
               {name:'Book Appoinment',link:'/Instant Service'},
               {name:'DigitalMarketing',link:'/Instant Service'},
               {name:'My mind space',link:'/Instant Service'},
               {name:'Doctor Login',link:'/DoctorDashboard'},
               {name:'Lab Login',link:'/LabPortal'},
               {name:'Medical Shop Login',link:'/MedicalShop/1'},

              ]

return( 
<div className={'mainbody001'}>
<MainHeader iconClicked={props.close} />
<SideBar show={props.showSideBar} close={props.close} menu={menu}/>   
{/* <SideBar/> */}
<div className="bodyimage">
  <img src={bg} alt="" className="herobg"/>
<div className='bodyletters'>
 <div className='letters'> 
    <div  className="letterArrangement001">Take Your Doctor With You</div>
    <div  className="letterArrangement002">Get expert consultations, medical deliveries, and health tests at your fingertips</div>
  </div>
  <div className="letterArrangement003">
    {/* <div className="letterArrangement004">Book Your Consultation Now</div> */}
    <div className="letterArrangement005">Book now</div> 
  </div>
</div>
</div>
{/* <Testing/> */}
<OfferList/>
<Articles/>
<div className='Footer'> 
<Footer/>
</div>   

</div>  
)

};
export default Home;