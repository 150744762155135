import React from 'react';

const PayementGateway = () => { 

var options = {    
"key": "rzp_test_yZP72wPUq09dvn", // Enter the Key ID generated from the Dashboard 
"amount": "500", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise 
"currency": "INR",  
"name": "Acme Corp", 
"description": "Test Transaction",
"image": "https://example.com/your_logo",
"order_id": "order_JtAk8hYsJj7Xgw", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
"handler": function (response){  
      alert(response.razorpay_payment_id);
      alert(response.razorpay_order_id);    
      alert(response.razorpay_signature)},
"prefill": {        
    "name": "Gaurav Kumar",       
    "email": "gaurav.kumar@example.com",     
    "contact": "9999999999"    
    },
"notes":  {
    "notes_key_1": "Tea, Earl Grey, Hot",
    "notes_key_2": "Tea, Earl Grey… decaf."
   },    
 "theme": {  "color": "#3399cc"    }};
 

 var rzp1 = new window.Razorpay(options);

 rzp1.on('payment.failed', function (response){       
    alert(response.error.code); 
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);});


const onCLickHandler = (e) => {
    rzp1.open();
    e.preventDefault();

}


        return (
            <div><button id="rzp-button1" onClick={onCLickHandler}>Pay</button></div>
        )

};

export default PayementGateway;



