// DoctorList.js
import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './DoctorList.module.css';
import { Link, Route , Routes } from 'react-router-dom';




const DoctorList = ({  }) => {
  // Filter doctors based on the selected category
  const { category } = useParams();
  const doctors = [
    { id: 1, name: 'Dr. John Doe', specialization: 'Cardiologist', availableTime: '9 AM - 5 PM' },
    { id: 2, name: 'Dr. Jane Smith', specialization: 'Dermatologist', availableTime: '10 AM - 6 PM' },
    { id: 2, name: 'Dr. Jane Smith', specialization: 'Dermatologist', availableTime: '10 AM - 6 PM' },
    { id: 2, name: 'Dr. Jane Smith', specialization: 'Dermatologist', availableTime: '10 AM - 6 PM' },
    { id: 2, name: 'Dr. Jane Smith', specialization: 'Dermatologist', availableTime: '10 AM - 6 PM' },
    { id: 2, name: 'Dr. Jane Smith', specialization: 'Dermatologist', availableTime: '10 AM - 6 PM' },
    // Add more doctors as needed
  ];   
  const categoryDoctors = doctors.filter(doctor => doctor.specialization.toLowerCase() === category);
 
  return (
    <div className={styles.listContainer}>
        
    <div>
      <h2>Doctors in {category}</h2>
      {/* Your actual list of doctors for the specific category goes here */}
    </div>
      {/* <h2>{category} Doctors</h2> */}
      <ul className={styles.doctorItems}>
        {categoryDoctors.map(doctor => (
          <li key={doctor.id} className={styles.doctorItem} >

            <strong className={styles.doctorName}>{doctor.name}</strong>
            <p className={styles.doctorDetails}>Specialization: {doctor.specialization}</p>
            <p className={styles.doctorDetails}>Available Time: {doctor.availableTime}</p>
            <Link to={`/doctor-details/${doctor.id}`}>
                <button>Book Now</button>
              </Link>
            {/* Add more details or actions as needed */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DoctorList;
