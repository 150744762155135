import { useSelector } from "react-redux";
import React from "react";
import BeekeyImage from '../../Assets/bm-icon-3.png';
import Styles from "./AuthDoctor.module.css";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {useState, useEffect} from 'react';
import { GetApI,PostAPI } from '../ApiModules/ApiInterFace';
import { useDispatch } from "react-redux";
import { AuthAction } from "../../Store/Store";




const  DoctorLogin = () => {
  
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [valid, setValid] =  useState(false);
  const [loading, setLoading] = useState(false)

  
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const onLoginOutput = (Response) => {
    setLoading(false);
    console.log(Response,"got the result")
    if (Response.outcome === "success"){
       localStorage.setItem("beekey-doctor",Response["beekey-doctor"])
       dispatch(AuthAction.login({...Response,role:'doctor'}))
    }else{
      console.log(Response.message,"error")  
    }}

  const login = async () => {
    console.log("calledlogin")
    setLoading(true)
    PostAPI({path:"/login/doctor"
             ,body:JSON.stringify({email,password})
             ,type:'application/json'
             ,callbackfunc:onLoginOutput
           });
}

useEffect(() => {
  GetApI({path:"/getdoctor"
  ,type:'application/json'
  ,callbackfunc:onLoginOutput
});
},[])

  useEffect(() => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false){
      setValid(false);
    }else{
        if(!password){
          setValid(false);
        }else{
          setValid(true)
        };
    }
  },[email,password])

 const formSubmitHandler = (e) =>{
  e.preventDefault();
 login();
 }

 return(
<div className={Styles.div0001}>
            <div className={Styles.div0004}>
                <div className={Styles.div0007}>
                 <div className={ loading ? Styles.sparker: Styles.sparkerinactive}></div> 
                <img src={BeekeyImage} className={Styles.beekeylogo}  alt='beekeymedicLogo'></img>
                </div>  

                <h3  className={Styles.h3002}>Sign in to your account</h3>
                <form className={Styles.form001}>
                    <div className={Styles.div0005}>
                    <Box  sx={{ Width: 230 }} >
                    <TextField  style={{width:"90%"}} id="email" label="Email" name='email' value={email} onChange={handleChangeEmail}/>
                   </Box> 
                    </div>
                    <div className={Styles.div0005}>
                      <Box  sx={{ Width: 230 }} >
                        <TextField  style={{width:"90%", }} id="password" label="Password" name='password' value={password} variant="outlined" onChange={handleChangePassword} />
                      </Box> 
                    </div>
                    <div>
                     <div className={Styles.div0006}>Forgot Password</div>   
                     <button className={ valid ? Styles.button002: Styles.button002inactive}  onClick={formSubmitHandler}>LOGIN</button>   
                    </div>
                </form>
            </div>
    </div>

 )
}; 



const AuthDoctor = (props) => { 
  const authState = useSelector(state => state.auth);
const doctor = 'doctor'
 if(authState.isAuthenticated){
   if(authState.user.role === doctor)
      console.log(authState.user,"hiiiii")
     {return <>{props.children}</>}
  };
  return <DoctorLogin/>
}; 

export default AuthDoctor;