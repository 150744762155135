// ProductGallery.js
import React, { useState } from 'react';
import styles from './ProductGallery.module.css';

const ProductGallery = ({ category, subcategories, products }) => {
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const handleSubcategoryChange = (event) => {
    setSelectedSubcategory(event.target.value);
  };

  const filteredProducts = selectedSubcategory
    ? products.filter((product) => product.subcategory === selectedSubcategory)
    : products;

  return (
    <div className={styles.productgallery}>
      <div className={styles.category}>
        <div className={styles.select}>
            <h2>{category}</h2>
            <select onChange={handleSubcategoryChange}>
              <option value="">All Subcategories</option>
              {subcategories.map((subcat, index) => (
                <option key={index} value={subcat}>
                  {subcat}
                </option>
              ))}
            </select>
        </div>
        <div className={styles.products}>
          {filteredProducts.map((product, index) => (
            <div className={styles.product} key={index}>
              <img src={product.image} alt={`Product ${index + 1}`} />
              <p className={styles.p1}>{product.name}</p>
              <p className={styles.p2}>₹{product.price}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductGallery;
