// Package.js
import React from 'react';
import styles from './Package.module.css';

const Package = ({ name, description, price }) => {
  return (
    <div className={styles.package}>
      <h3>{name}</h3>
      <p className={styles.description}>{description}</p>
      <p className={styles.price}>Price: ${price}</p>
    </div>
  );
};

export default Package;
