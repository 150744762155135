import styles from './PlaceMedicineOrder.module.css';
import {Link} from 'react-router-dom';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {PostAPI} from '../ApiModules/ApiInterFace';

const  PlaceMedicineOrder = (props) => {
  
const history = useNavigate();

const [selected, setSelected] = useState(null);
const Address = useSelector(state => state.auth.user.address)   
const User = useSelector(state => state.auth.user) 


 const handleClicked = (index) => {
   console.log("handleclicked")
    setSelected(prev => {
      if(prev === index){
         return(null)
      }else{
         return(index)}})
 };



const onReplyforaPlaceOrder = (Response) => {
   console.log(Response,"got the result")
   if (Response.outcome === "success"){
      history('/Branding/oderplaced/'+ Response.uuid)
   }else{
     console.log(Response.message)  
   }};



const handleOnClickPlaceOrder = () => {
PostAPI({
   path: '/putorder'
   ,body:JSON.stringify({type:'medicines'
                        ,consumer:User.uuid
                        ,payement_type:"cash_on_delivery"
                        ,payement:"not_given"
                        ,details:{delivery_address: Address[selected],precription:props.presciption}
                        ,notes:'medicine order booking' 
                     })
   ,type:'application/json'
   ,callbackfunc:onReplyforaPlaceOrder
})
} 

return(
  <div >
    <div className={styles.div0000}>
        <p className={styles.para0000}>Delivered To</p>
       {Address.map((e,index) => {         
         return(<div className={selected === index ?styles.div0001:styles.div0001withoutSelect} key={index} onClick={() => handleClicked(index)}>
         <div className={styles.subdiv0001a}>
            <p className={styles.para0001}><b>{e.buldingName}</b></p>
            <p className={styles.para0001}>{e.street},{e.taluk}</p>
            <p className={styles.para0001}>{e.district},{e.state}</p>
            <p className={styles.para0001}>Pincode:{e.pin}</p>
         </div>
         <div className={styles.subdiv0001b}>
            <p>9072411185</p>
            <p>tik</p>
         </div>
       </div>)})
       }

       <div className={styles.div0002}>
          ADD ADDRESS
       </div>
    </div>
    <div className={styles.div0000}>
       <p className={styles.para0002}>Enter coupon code</p>
       <input className={styles.input001} placeholder='Enter Coupen Code' type='text'/>
    </div>
    <div className={styles.div0000}>
       <p className={styles.para0003}>PAYMENT METHOD</p>
       <div style={{display:'flex',alignItems:'center',padding:'auto'}}>
         <input className={styles.input0002}  type="radio" id="html" name="fav_language" value=""/>
         <label className={styles.label0001}  for="html">Cash On Delivery</label>
        </div>
   </div>
   <footer className={styles.button0001} onClick={handleOnClickPlaceOrder}>
       <p>PLACE ORDER</p>
   </footer>
  </div>
  )
};
export default PlaceMedicineOrder;
