import developerImg from '../../Assets/medicalshop-pic.png';
import softwareImg from '../../Assets/Stem-cell research-rafiki.png';
import buisnessImg from '../../Assets/Medicine-pana.png';
import React from 'react';








const Quality = (props) => {
 if(props.id === "001"){
   var Img = developerImg;
 }   
 if (props.id ==="002") {
    Img =buisnessImg
 };
 if(props.id ==="003"){
    Img=softwareImg
 }

    return(
        <div style={{display:"flex",flexDirection:"column",maxheight:"900px",maxwidth:"400px",justifyContent:"center",alignItems:"center"}}>
            <img src={Img} alt="quality icon" style={{width:"200px",height:"200px",borderRadius:"50%",objectFit:"contain"}}/>
            <div style={{marginTop:"20px", fontSize:"20px",fontWeight:"500"}}>{props.quality}</div>
            <div style={{width:"200px",marginTop:"20px",textAlign:"center", fontSize:"15px",fontWeight:"200"}}>{props.description}</div>
        </div>
    )
};
export default Quality; 