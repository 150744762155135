import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, TextField } from '@mui/material';
import styles from './PersonalSection.module.css';  
import DocIcon from '../../Assets/docicon.jpg';
import { useSelector } from 'react-redux';

const PersonalSection = () => {
  const authState = useSelector(state => state.auth);
  console.log(authState,"user##")
  const [editing, setEditing] = useState(false);
    const [doctorData, setDoctorData] = useState({
      name: 'Dr. John Doe',
      title: 'General Practitioner',
      specialization: 'Internal Medicine',
      location: '123 Main St, Anytown, USA',
      phone: '123-456-7890',
      email: 'johndoe@example.com',
      image:{DocIcon},
    });

  const handleEdit = () => {
    setEditing(true);
  };

  const handleSave = (event) => {
    event.preventDefault();
    setEditing(false);
    // You can save the updated doctor data here
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDoctorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Card className={styles.Personalsection} sx={{ minWidth: 275 }}>
      <CardContent>
        {!editing ? (
          <>
          <img src={authState.user.picture} alt=""/>
          <img src={doctorData.image} alt=""/>

            <Typography className={styles.head} variant="h5" component="h2">
              {doctorData.name}
            </Typography>
            <Typography className={styles.subtext} color="textSecondary" gutterBottom>
              {doctorData.title}
            </Typography>
            <Typography className={styles.text} variant="body2">
              <strong>Specialization:</strong> {doctorData.specialization}
            </Typography>
            <Typography className={styles.text} variant="body2">
              <strong>Location:</strong> {doctorData.location}
            </Typography>
            <Typography className={styles.text} variant="body2">
              <strong>Phone:</strong> {doctorData.phone}
            </Typography>
            <Typography className={styles.text} variant="body2">
              <strong>Email:</strong> {doctorData.email}
            </Typography>
            <button className={styles.updateButton} onClick={handleEdit}>Update Personal Info</button>
          </>
        ) : (
          <form onSubmit={handleSave}>
            <TextField
              className={styles.inputField}
              label="Name"
              name="name"
              value={doctorData.name}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              className={styles.inputField}
              label="Title"
              name="title"
              value={doctorData.title}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              className={styles.inputField}
              label="Specialization"
              name="specialization"
              value={doctorData.specialization}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              className={styles.inputField}
              label="Location"
              name="location"
              value={doctorData.location}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              className={styles.inputField}
              label="Phone"
              name="phone"
              value={doctorData.phone}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField
              className={styles.inputField}
              label="Email"
              name="email"
              value={doctorData.email}
              onChange={handleChange}
              fullWidth
              required
            />
            <TextField type="file" 
              label="Profile Image"
              name="image"
              value={doctorData.image}
              onChange={handleChange}
            />
      <Button variant="contained" color="primary" component="span" >
        Upload
      </Button><br/>
            <button className={styles.saveButton} type="submit">Save</button>
            <button className={styles.cancelButton} onClick={handleCancel}>Cancel</button>
          </form>
        )}
      </CardContent>
    </Card>
  );
};

export default PersonalSection;
