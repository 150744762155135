import styles from './UserHome.module.css';
import img4 from '../../../Assets/ai.jpg';
import img5 from '../../../Assets/nano.webp';
import img6 from '../../../Assets/hyper.jpeg';
import img1 from '../../../Assets/banner1.jpg';
// import AwesomeSlider from 'react-awesome-slider';
import Offer from './../Offer';
// import Carousel from "react-multi-carousel";
import React from 'react';
import ResponsiveSlider from '../../UI/slider/Slider';
import ProductGallery from '../../Products/ProductGallery';

const UserHome = () => {
   
 
 

  //add products
 const electronicsProducts = [
  { image: img4, name: 'Product A', price: 99.99,subcategory: 'Subcategory 1' },
  { image: img5, name: 'Product B', price: 129.99,subcategory: 'Subcategory 2' },
  { image: img6, name: 'Product B', price: 129.99,subcategory: 'Subcategory 3' },
  { image: img4, name: 'Product A', price: 99.99,subcategory: 'Subcategory 1' },
  { image: img5, name: 'Product B', price: 129.99,subcategory: 'Subcategory 3' },
  { image: img6, name: 'Product B', price: 129.99,subcategory: 'Subcategory 2' },
  // Add more products as needed
];
const electronicsSubcategories = [ 'Subcategory 1', 'Subcategory 2','Subcategory 2'];


//slider
const slides = [
  {image:img1,content: 'Slide 1 content'},
  {image:img1,content: 'Slide 2 content'},
  {image:img1,content: 'Slide 3 content'},
]


//top section 
 const defaultOffers = [{id:"001"
 ,icon:"Insta Doc"
 ,img: "laptop-code"
 ,description:"Consult Online Now"
 ,message: "Doctors 24/7?"                 
},{id:"002"
  ,icon:"Book Appointment"
  ,img:"envelopes-bulk"
  ,description:"Online/Clinic"
  ,message: "Curated Doctors"                      
},{id:"003"
,icon:"Book Appointment"
,img:"envelopes-bulk"
,description:"Online/Clinic"
,message: "Curated Doctors"                      
},
{id:"004"     
,icon:"Book Appointment"
,img:"envelopes-bulk"
,description:"Online/Clinic"
,message: "Curated Doctors"                 
}];

    return( <div>  
    <div className={styles.maindiv2}>             
            {defaultOffers.map((e) => 
             <div className={styles.div0011}><Offer key={e.id} 
                  id={e.id}
                  icon={e.icon}
                  description={e.description}
                  message={e.message}
                /></div>)}
               
             </div>
             <div className={styles.maindiv3}> 
                <ResponsiveSlider slides={slides}/>
             </div>
      
         <div className={styles.maindiv4}>
            <div  className={styles.div0014}>
              <ProductGallery category="Electronics" subcategories={electronicsSubcategories} products={electronicsProducts} />
            </div>
            <div  className={styles.div0015}>
              <h1>Latest For You</h1>
              <div  className={styles.div0015a}>
                <div>
                  <img src={img4} alt=""/>
                  <h3>Latest</h3>
                  <p>Information post</p>
                </div>
                <div>
                  <img src={img5} alt=""/>
                  <h3>Latest</h3>
                  <p>Information post</p>
                </div>
                <div>
                  <img src={img6} alt=""/>
                  <h3>Latest</h3>
                  <p>Information post</p>
                </div>
              </div>
            </div>

         </div>
     </div> 
    )
};

export default UserHome