// const BasePath = 'http://localhost:8084/v1'

const BasePath = 'https://beekeymedics.com/v1'

const ClientAuth = localStorage.getItem('beekey-app');
const UserAuth = localStorage.getItem('beekey-user');
const DocAuth = localStorage.getItem('beekey-doctor')


export const GetApI = async (props) => {
  try{
    console.log(BasePath, props.path)
    const object =  {
              method:'GET', 
              headers: {'Content-Type':'application/x-www-form-urlencoded'
                        ,'beekey-app':ClientAuth,'beekey-user':UserAuth, 'beekey-doctor':DocAuth},
              credentials: 'include'}
    const response = await fetch(BasePath + props.path,object)
    const data     = await response.json();
    props.callbackfunc(data)
  }catch(error){
     props.callbackfunc({"outcome":"failed"})
     console.log(error,"dfvd")
     return("error")
  };
};

export const PostAPI = async (props) => {
    const extendPath = props.path; 
    const object = {
        method:'POST', 
        headers: {'Content-Type':props.type
                   ,'beekey-app':ClientAuth,'beekey-user':UserAuth,'beekey-doctor':DocAuth},
        credentials: 'include',
        body:props.body}
    try{
      const response = await fetch(BasePath + extendPath, object)
      const data     = await response.json();
       props.callbackfunc(data)
      return(data)
    }catch(error){
       console.log(error)
       return("error")
    };
  };

export const put_api = async (props) => {
    const extendPath = props.path;
    const body = props.body; 
    const object = {
        method:'PUT', 
        headers: {'Content-Type':'application/json'
                  ,'beekey-app':ClientAuth,'beekey-user':UserAuth, 'beekey-doctor':DocAuth},
        body:JSON.stringify(body)}
    try{
      const response = await fetch(BasePath + extendPath, object)
      const data     = await response.json();
      console.log(data,"pppppppppppppppppp")
      return(data)
    }catch(error){
       console.log(error)
       return("error")
    };
  };

  export const delete_api = async (props) => {
    const extendPath = props.path;
    const body = props.body; 
    const object = {
        method:'DELETE', 
        headers: {'Content-Type':'application/json'
                  ,'beekey-app':ClientAuth,'beekey-user':UserAuth,'beekey-doctor':DocAuth},
        body:JSON.stringify(body),
        credentials: 'include'
      }
    try{
      const response = await fetch(BasePath + extendPath, object)
      const data     = await response.json();
      props.callbackfunc(data)
      return(data)
    }catch(error){
       console.log(error)
       return("error")
    };
  };