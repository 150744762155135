//-------------------------------------------------Imports---------------------------------------------------------------//
import React, { useState, useEffect } from 'react';
import { GetApI,PostAPI, delete_api} from '../ApiModules/ApiInterFace';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import SlotsModal from './SlotsModal';
import './DoctorCalendar.css';
import BookingModal from '../UI/BookingModal';


const localizer = momentLocalizer(moment);  /* moment-timezone */

const  DoctorCalendar = (props) => {       /*exported function*/
  

//----------------------------------------------------State ---------------------------------------------------------------//

  const [availableSlots, setavailableSlots] = useState([]); 

  const [isModalOpen,setIsModalOpen]= useState(null);
  const [payementProceed, setPayementProceed] =useState(null);
  
  console.log(payementProceed)


//  -----------------------------------------------------------------------------------------------------------------------//  
    useEffect(() => {
      console.log('trying to hit')
        GetApI({path:"/getconsults",callbackfunc:updateConsults}) 
    },[]);

//  -------------------------------------------------API CALL BACKS -------------------------------------------------------//
    const updateConsults = (Data) => {
      if (Data.outcome === "success"){
        const newconsult = Data.consults.map((e) =>{
          return (
            { id: e.uuid,
              title: e.title,
              start: new Date(e.start_time),
              end: new Date(e.end_time),
              resource: e.patient_details
            })
        }
        );
        setavailableSlots(newconsult); 
      }else{
        console.log("error in getting consults")
      }
    };


    const updateConsultAck = (Data) => {
      if (Data.outcome === "success"){
        const newconsult = 
          { id      : Data.consult.uuid,
            title   : Data.consult.title,
            start   : new Date(Data.consult.start_time),
            end     : new Date(Data.consult.end_time),
            resource: Data.consult.patient_details
          }
        setavailableSlots(prev => [...prev.filter(e => e.id !== newconsult.id), newconsult]); 
        const obj = {keyid:Data.consult.patient_details.key
          ,amount:Data.consult.final_amount
          ,order_id:Data.consult.patient_details.order_id
          ,username:Data.consult.user.firstname + " " + Data.consult.user.lastname
          ,useremail:Data.consult.user.email
          ,userphone:Data.consult.user.phone}
        setPayementProceed(obj)
      }else{
        console.log("error in getting consults")
      }
    }; 

    const getconsult = (Data) => 
      {if (Data.outcome === "success"){
        { closeModal()
          handleGetconsult()}}
        else{
          console.log("error in getting consults")
        }
      };
//---------------------------------------------------API CALLS -----------------------------------------------------------//

  const handleGetconsult = () => 
    {
      GetApI({path:"/getconsults",callbackfunc:updateConsults}) 
    };

  const handleSelectedSlot = (arg) => {  
    console.log(arg.slots)
    const newslots =
        arg.slots.map((e, I) => {
        if (I < arg.slots.length - 1) {
          return (
            { start_time    :e.getTime(),
              end_time      :arg.slots[I + 1].getTime(),
              normal_amount :3000           
            })};
          return null; 
      }).filter(item => item !== null);
    PostAPI({path:"/putconsult"
      ,body:JSON.stringify({consults:newslots})
      ,type:'application/json'
      ,callbackfunc:updateConsults})};


  const handleDeleteEvent = (uuid) => {
    delete_api({path:`/deleteconsult/${uuid}`
    ,body:JSON.stringify({})
    ,type:'application/json'
   ,callbackfunc:getconsult})};
  

//------------------------------------------------INTERNAL FUNCTIONS -----------------------------------------------------//


  const checkDoctoravailableDate = (dateString,Duration) => {
    const currentDate     = new Date(dateString);
    const incrementedDate = new Date(currentDate.getTime() +Duration);
    const doctorAvailbleSlot = availableSlots.filter(slot => {
      return (slot.start >= currentDate && slot.end <= incrementedDate)&&currentDate.getDate() ===slot.start.getDate();
    });
    if(doctorAvailbleSlot.length > 0){
       const isBooked  = doctorAvailbleSlot.filter(slot => {
         return Object.keys(slot.resource).length !== 0;
       });
       if(isBooked.length > 0){
         return {className:"doc-booked-day"};
       }else{
         return {className:"doc-available-day"};
       }
    }else{
      return {className:"doc-absent-day"};
    }
  };
const openModal= (e) =>{
  setIsModalOpen(e);
  // setBooking(e);
};
const [booking , setBooking ]=useState();
const closeModal= () =>{
  setIsModalOpen(null)
};
//-------------------------------------------REACT BIG CALENDAR -------------------------------------------------------//
  return (
    <div style={{ height: 900}}>
     <BigCalendar
      localizer={localizer}
      events={availableSlots}
      step={15}
      selectable ={true}
      defaultView="month"
      onSelectEvent={(event)   => openModal(event) }  
      onSelectDay={(event)   => console.log('%Selected Event:', event)}
      dayPropGetter={(e) => checkDoctoravailableDate(e,24*60*60*1000)}
      // slotPropGetter={(e) => checkDoctoravailableDate(e,15*60*1000)}
      eventPropGetter={(e) =>{return{className:(Object.keys(e.resource).length !== 0)?"booked_event":""}}}
      onSelectSlot={handleSelectedSlot}
      views={['month', 'week', 'day']}
      defaultDate={new Date()}
      startAccessor="start"
      endAccessor="end"
      popup={true}
      timeslots={1}
      // onShowMore={(events, date) => this.setState({ showModal: true, events })}
    />
    {isModalOpen !== null && <BookingModal onClose={closeModal} slot={isModalOpen} deleteConuslt={handleDeleteEvent}  />} 
     </div>
  );
}

export default  DoctorCalendar; /*EXPORT*/

//--------------------------------------------The End---------------------------------------------------------//