import React from 'react';
import './BookingModal.css';
import unReservedImg from '../../Assets/unreserved.png';
import unReservedImg2 from '../../Assets/unreserved2.png'

const BookingModal = ({ onClose ,slot, deleteConuslt}) => {
  console.log(slot.resource ,"5555555555");
  if (Object.keys(slot.resource).length == 0) {
    return (
      <div className="modal-container" >
      <div className="modal">
        {/* Modal content for empty object */}
        <div className="modal-content2">
          <img className='w-75 p-1 ' src={unReservedImg2}/>
          <p>No booking yet</p>
          <div className='d-flex justify-content-around'>
          <button onClick={onClose} className="btn btn-light">Close</button>
          <button onClick={() => deleteConuslt(slot.id)} className="btn btn-danger">Delete</button>
          </div>
        </div>
      </div>
      </div>
    );
  }
  const {firstname, lastname, age, issue} = slot.resource;
  return (
    <div className="modal-container" >
      <div className="modal" >     
          {/* <p>{console.log(booking,"kppppppp")}</p> <br/> */}
          <h2>Booking Details</h2>
          <div className='modal-content'>
            <ul>
              <li>Name:<span>   {`${firstname}  ${lastname}`} </span></li>
              <li> Age: <span>{age}</span></li>
              <li>Struggling with: <span> {issue}</span></li>
            </ul>
          </div>
          <div className='buttons-container'>
              <button  onClick={onClose} className='save-button'>ok</button>
          </div>      
      </div>
    </div>
  );
};

export default BookingModal;




