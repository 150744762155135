import img1 from '../../Assets/beekeymedic.jpeg';
import Styles from './MedicalShoplogin.module.css';
import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {useState, useEffect} from 'react';
import { GetApI,PostAPI } from '../ApiModules/ApiInterFace';
import { useDispatch } from "react-redux";
import { AuthAction } from "../../Store/Store";
import { useSelector } from 'react-redux';
import BeekeyImage from '../../Assets/bm-icon-3.png';

const MedicalShoplogin =(props) =>{


  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [valid, setValid] =  useState(false);
  const [loading, setLoading] = useState(false)

  
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const onLoginOutput = (Response) => {
    setLoading(false);
    console.log(Response,"got the result")
    if (Response.outcome === "success"){
       localStorage.setItem("beekey-medicalshop",Response["beekey-medicalshop"])
       dispatch(AuthAction.login({...Response,role:'medicalshop'}))
    }else{
      console.log(Response.message,"error")  
    }}

  const login = async () => {
    console.log("calledlogin")
    setLoading(true)
    PostAPI({path:"/login/medicalshop"
             ,body:JSON.stringify({email,password})
             ,type:'application/json'
             ,callbackfunc:onLoginOutput
           });
}

useEffect(() => {
  GetApI({path:"/getmedicalshop"
  ,type:'application/json'
  ,callbackfunc:onLoginOutput
});
},[]);

useEffect(() => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false){
      setValid(false);
    }else{
        if(!password){
          setValid(false);
        }else{
          setValid(true)
        };
    }
  },[email,password])

 const formSubmitHandler = (e) =>{
  e.preventDefault();
 login();
 }


const loginpage = 
    <div className={Styles.div0001}>
        <div className={Styles.div0002}>
            {/* <div className={Styles.div0003q}>
            <div className={Styles.div0003}>
                <h2 className={Styles.h2001}>Sell with Beekey medic, now easy!</h2>
                <h3  className={Styles.h3001}>List your shops and start your <br/>business with Beekey Medic</h3>
                <p className={Styles.para001}>Start selling absolutely free. All you need is to register, list your<br/> catalogue and start selling your products.</p>
                <button className={Styles.button001}>start selling</button>
            </div>
            </div> */}
            <div className={Styles.div0004}>
               <div className={Styles.div0007}>
                 <div className={ loading ? Styles.sparker: Styles.sparkerinactive}></div> 
                <img src={BeekeyImage} className={Styles.beekeylogo}  alt='beekeymedicLogo'></img>
                </div>
                <h3  className={Styles.h3002}>Sign in to your account</h3>
                <form className={Styles.form001}>
                    <div className={Styles.div0005}>
                    <Box  sx={{ Width: 230 }} >
                    <TextField  style={{width:"90%"}} id="email" label="Email" name='email' onChavariant="outlined" value={email} onChange={handleChangeEmail}/>
                   </Box> 
                    </div>
                    <div className={Styles.div0005}>
                    <Box  sx={{ Width: 230 }} >
                        <TextField  style={{width:"90%", }} id="password" label="Password" name='password' value={password} variant="outlined" onChange={handleChangePassword} />
                      </Box>
                    </div>
                    <div>
                     <div className={Styles.div0006}>Forgot Password</div>   
                     <button className={ valid ? Styles.button002: Styles.button002inactive}  onClick={formSubmitHandler}>Login</button>   
                    </div>
                </form>
            </div>
        </div>
    </div>;

    const authState = useSelector(state => state.auth);

    // if(authState.isAuthenticated){
    //   if(authState.user.role === 'medicalshop')
        {return <>{props.children}</>}
    //  };
    //  return loginpage

};

export default  MedicalShoplogin;