import {configureStore, createSlice} from "@reduxjs/toolkit";
import React from 'react';

const initialAuthState = {isAuthenticated: false};


const authSlice = createSlice({
  name:"auth",
  initialState:initialAuthState,
  reducers:{
    login(state,props){
      console.log("login")
      return({isAuthenticated: true,
              user:props.payload
           }  
        );
    },
    logout(state){
      state.isAuthenticated = false;
    },
  }
});


export const AuthAction = authSlice.actions;
const store = configureStore({reducer:{auth:authSlice.reducer}});

export default store;
