import styles from './OrderStatus.module.css';
import React from 'react';
import {useParams} from 'react-router-dom';
import { hexToRgb } from '@mui/material';

const OrderStatus = (props) => {

console.log(props.order)

let height = 49  ;
    if (height === 100){
         height = 100}
    else if ( height >= 75){
         height = ((height - 4)/100)*75  + 21.9} 
    else if (height >= 50){
         height = ((height - 3)/100)*75  + 16.8}
    else if (height >= 25){
         height = ((height- 2)/100)*75  + 11.5}
    else if(height >= 1){
         height = ((height - 1)/100)*75 + 6.3
    }else{
         console.log(height)
    };     
 console.log(height)     
const fill = (height/100)*58

return(
    <div  className={styles.div000}>
    <div className={styles.div001}>
        <div className={styles.div002}>
        <div className={styles.div004}>
             <div className={height >= 1 ? styles.div003fill:styles.div003}><p className={styles.p001}>1</p></div>
             <div className={height >= 25  ? styles.div003fill:styles.div003}><p className={styles.p001}>2</p></div>
             <div className={height >= 50  ? styles.div003fill:styles.div003}><p className={styles.p001}>3</p></div>
             <div className={height >= 75  ? styles.div003fill:styles.div003}><p className={styles.p001}>4</p></div>
             <div className={height >= 100 ? styles.div003fill:styles.div003}><p className={styles.p001}>5</p></div>
        </div>    
        <div className={styles.divfill} style={{height: String(fill)+"%"}}>
        </div>
        </div>
     </div>
     <div className={styles.div001a}>
            <div className={height >= 1 ? styles.div003boxfill:styles.div003box}><p className={styles.p002}>Order placed</p></div>
            <div className={height >= 25 ? styles.div003boxfill:styles.div003box}><p className={styles.p002}>Started Processing Order</p></div>
            <div className={height >= 50 ? styles.div003boxfill:styles.div003box}><p className={styles.p002}>Ready for shiping</p></div>
            <div className={height >= 75 ? styles.div003boxfill:styles.div003box}><p className={styles.p002}>Order Shipped</p></div>
            <div className={height >= 100 ? styles.div003boxfill:styles.div003box}><p className={styles.p002}>Deliverd</p></div>
     </div>
    </div>
)
};

export default OrderStatus;