import styles from './Lab.module.css';
import beekey from '../../Assets/beekey.jpeg';
import img1 from '../../Assets/devimg.png';
import img2 from  '../../Assets/protected3.png';
import img3 from  '../../Assets/prescription.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'; 
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/styles.css';
import React from 'react';


const LavailableLabtest  = [
   {test:"complete blood test",number:"7", mprice: 200,aprice:100, homeCollection:true},
   {test:"B.P test",number:"3", mprice: 300,aprice:100, homeCollection:true},
   {test:"shugar test",number:"7", mprice: 900,aprice:700, homeCollection:true},
   {test:"colestrol test",number:"1", mprice: 500,aprice:300, homeCollection:true},
   {test:"eye test",number:"2", mprice: 200,aprice:100, homeCollection:false},
   {test:"Covid test",number:"2", mprice: 1500,aprice:800, homeCollection:true}
]


const Lab = () => {
return(
    <div className={styles.main}>
        <div style={{marginLeft:"2%",marginTop:"7%",width:"700px"}}>
        <div style={{width:"130px",display:"flex",margin:"4%",}}>
                  <img src={beekey} alt="Beekey Logo"  className={styles.beekeylogo}/>
               <div style={{marginLeft:"10px",marginTop:"10px"}}>
                  Beekey <br/>Innovative 
               </div>
              </div>
        <div style={{marginLeft:"10%",marginTop:"10%"}}>
           <p style={{fontSize:"50px",fontWeight:"600",fontFamily:"'Trebuchet MS', 'Lucida Sa5ns Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif",color:"#EF10E0",textShadow:"4px 5px #F9A6F4",}}>LOGO DESIGN</p>
           <p style={{fontSize:"27px",fontWeight:"600",color: "rgba(0,0,0,0.5)"}} >Our Developers avaliable anytime,anywhere<br/>
            with 24*7  availble just for you.</p>
            <div style={{display:"flex",marginTop:"20px"}}><img src={img1} alt="protection" className={styles.img}/> <p style={{marginTop:"60px", marginLeft:"10PX",fontSize:"20PX"}}> 24x7 availbe developers</p></div>
            <div style={{display:"flex",marginTop:"40px"}}><img src={img2} alt="protection" className={styles.img}/> <p style={{marginTop:"60px", marginLeft:"10PX", fontSize:"20PX"}}> Completely confidential</p></div>
        </div>
        </div>
        <div style={{width:"25%",borderLeft:"1px solid blue", display:'flex',flexDirection:'column',overflowY:"scroll"}}>
         <div style={{display:'flex',height:"6%",padding:'7px',boxShadow:"0px 0px 3px rgba(0,0,0,0.3)"}}>
            <FontAwesomeIcon icon={solid("align-right")} size="2x" className={styles.icon}/>
            <div style={{width:"130px",display:"flex",marginLeft:"25%"}}>
                <img src={beekey} alt="Beekey Logo"  className={styles.beekeylogo}/>
             <div style={{marginLeft:"10px",marginTop:"10px"}}>
                Beekey <br/>Innovative 
             </div>
            </div>
         </div>
        <AwesomeSlider animation="cubeAnimation" organicArrows={true} bullets={false} infinite={true}>
         <div style={{height:"230px",width:"90%"
                      ,border:"2px solid #540A36"
                      ,margin: "5%",borderRadius:"10px"
                      ,color:"white"
                      ,textAlign:"center"
                      ,backgroundImage: "linear-gradient(to right,#756798, white)"}}>
          <p style={{margin:"25%",fontSize:"20px"}}>Put some content</p>
         </div>
         <div style={{height:"230px",width:"90%"
                      ,border:"2px solid #540A36"
                      ,margin: "5%",borderRadius:"10px"
                      ,color:"white"
                      ,textAlign:"center"
                      ,backgroundImage: "linear-gradient(to right,#540A36, white)"}}>
          <p style={{margin:"25%",fontSize:"20px"}}>Put some content</p>
         </div>
         <div style={{height:"230px",width:"90%"
                      ,border:"2px solid #540A36"
                      ,margin: "5%",borderRadius:"10px"
                      ,color:"white"
                      ,textAlign:"center"
                      ,backgroundImage: "linear-gradient(to right,#EA1515, white)"}}>
          <p style={{margin:"25%",fontSize:"20px"}}>Put some content</p>
         </div>
         <div style={{height:"230px",width:"90%"
                      ,border:"2px solid #540A36"
                      ,margin: "5%",borderRadius:"10px"
                      ,color:"white"
                      ,textAlign:"center"
                      ,backgroundImage: "linear-gradient(to right,#E4951B, white)"}}>
          <p style={{margin:"25%",fontSize:"20px"}}>Put some content</p>
         </div>
         </AwesomeSlider>
         {LavailableLabtest.map((e) => <div className={styles.content004}>
            <div className={styles.content005}>
               <div className={styles.content009}>
                  <img src={img3} className={styles.img001} alt="lab test"/>
               </div>
               <div className={styles.content010}>
                  <p className={styles.para003}> {e.test}</p>
                  <p className={styles.para004}>Include {e.number} tests</p>
                  <p className={styles.para005b}>Nabl</p>
               </div>
               <div className={styles.content017}>discount</div>
            </div>
            <div className={styles.content006}>
              <div className={styles.content011}>  
               <div className={styles.content008} >
                   <p className={styles.para005}>{e.aprice}rs</p>
                   <del className={styles.del001}>{e.mprice}rs</del>
                   <p className={styles.para005a}>yousave {e.mprice - e.aprice}rs</p>
              </div>
               {e.homeCollection && <p className={styles.para007}> Home sample collection available</p>}
              </div>
              <div>
               <button className={styles.button001}>add</button>
              </div>
            </div>
         </div>)}
      </div>
      </div>
)
}; 


export default Lab;