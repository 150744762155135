
import * as React from 'react';
import { useState,useEffect } from "react";
import usericon from'../../Assets/user-icon.png';
import styles from './Dashboard.module.css';
import { PostAPI } from '../ApiModules/ApiInterFace';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';




function TotalbookingSection() {


    const [totalConsult, setTotalConsult] = useState([]);


    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
          field: 'firstName',
          headerName: 'First name',
          width: 150,
          editable: true,
        },
        {
          field: 'lastName',
          headerName: 'Last name',
          width: 150,
          editable: true,
        },
        {
          field: 'age',
          headerName: 'Age',
          type: 'number',
          width: 50,
          editable: true,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 110,
            editable: true,
          },
          {
            field: 'email',
            headerName: 'Email',
            width: 220,
            editable: true,
          },
        ];

    const onGetTotalConsultsInfo = (Response) => {
        // setLoading(false);
        console.log(Response,"got the result")
        if (Response.outcome === "success"){
            const ModifiedResult =  Response.result.map((e,i) =>
            {return({id:i,
                     firstName:e.user.firstname,
                     lastName:e.user.lastname,
                     phone:e.user.phone,
                     age:e.user.age,
                     email:e.user.email,

           })}) 
            setTotalConsult(ModifiedResult)
        }else{
          console.log(Response.reason,"error")  
        }}
    
        
      const getTotalConsults = async () => {
        // setLoading(true)
        PostAPI({path:"/doctor/consults"
                 ,body:JSON.stringify({})
                 ,type:'application/json'
                 ,callbackfunc:onGetTotalConsultsInfo
               });
    }
    
    useEffect(() => {
        getTotalConsults() ;
    
    },[])


    return <div className={styles.container}>
    {/* <h2 style={{marginTop:"20px"}}>Total Booking Section</h2> */}
   



    <div className={styles.totalsub}>
          <div className={styles.totalsubcontainer}>
            <img src={usericon}  alt=""/>
            <div className={styles.totalcount}>
                <h5>Total Bookings</h5>
                <strong><h4 style={{marginLeft:"5px"}}>{totalConsult.length}</h4></strong> 
            </div>
            <a href="#"style={{fontSize:"12px",marginTop:"5px",marginLeft:"0"}}>View More</a>
          </div>
        </div>
   
    <div className={styles.totaltable}>
    <h5>Total Bookings</h5>
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={totalConsult}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        // disableRowSelectionOnClick
      />
    </Box>
    </div>
    
</div>;
  }
  export default TotalbookingSection;