
import * as React from 'react';
import { useState, useEffect } from "react";
import usericon from'../../Assets/user-icon.png';
import doctor1 from'../../Assets/doctor1.png';
import styles from './Dashboard.module.css';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { PostAPI } from '../ApiModules/ApiInterFace';


function DashboardSection() {
  const [dashboard, setDashboard] = useState({})


  const onGetDashboardInfo = (Response) => {
    // setLoading(false);
    console.log(Response,"got the result")
    if (Response.outcome === "success"){
       setDashboard(Response.result)
    }else{
      console.log(Response.reason,"error")  
    }}

    console.log(dashboard,"rrrr")

  const getDashoard = async () => {
    // setLoading(true)
    PostAPI({path:"/doctor/dashboard"
             ,body:JSON.stringify({})
             ,type:'application/json'
             ,callbackfunc:onGetDashboardInfo
           });
}

useEffect(() => {
  getDashoard() ;

},[])





//  Graph data start
  const data = [
    {x: 0, y: 8},
    {x: 3, y: 5},
    {x: 2, y: 4},
    {x: 6, y: 9},
    {x: 4, y: 1},
   ];
//  Graph data ends

    const columns = [
      { field: 'id', headerName: 'ID', width: 90 },
      {
        field: 'firstName',
        headerName: 'First name',
        width: 150,
        editable: true,
      },
      {
        field: 'lastName',
        headerName: 'Last name',
        width: 150,
        editable: true,
      },
      {
        field: 'age',
        headerName: 'Age',
        type: 'number',
        width: 40,
        editable: true,
      },
      // {
      //   field: 'fullName',
      //   headerName: 'Full name',
      //   description: 'This column has a value getter and is not sortable.',
      //   sortable: false,
      //   width: 160,
      //   valueGetter: (params) =>
      //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      // },
    ];
    
    const rows = [
      { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
      { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
      { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
      { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
      { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
      { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
      { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
      { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
      { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
    ];
    


    return <div className={styles.container}>
    <div style={{marginLeft:"20px",marginTop:"20px"}}>
        {/* <h2>Dashboard</h2> */}
        <p>Welcome back Doctor </p>
    </div>
    <div className={styles.totalsection}>
        <div className={styles.offer} style={{width:"100%"}}>
          <img src={doctor1}  alt=""/>            
        </div>

        <div className={styles.totalsub}>
          <div className={styles.totalsubcontainer}>
            <img src={usericon}  alt=""/>
            <div className={styles.totalcount}>
                <h5>Active Bookings</h5>
                <strong><h4 style={{marginLeft:"5px"}}>{dashboard.active_booking}</h4></strong> 
            </div>
            <a href="#"style={{fontSize:"12px",marginTop:"5px",marginLeft:"0"}}>View More</a>
          </div>
        </div>
        
        <div className={styles.totalsub}>
          <div className={styles.totalsubcontainer}>
            <img src={usericon}  alt=""/>
            <div className={styles.totalcount}>
                <h5>Total Bookings</h5>
                <strong><h4 style={{marginLeft:"5px"}}>{dashboard.total_booking}</h4></strong> 
            </div>
            <a href="#"style={{fontSize:"12px",marginTop:"5px",marginLeft:"0"}}>View More</a>
          </div>
        </div>
        <div className={styles.totalsub}>
          <div className={styles.totalsubcontainer}>
            <img src={usericon}  alt=""/>
            <div className={styles.totalcount}>
                <h5>Earnings</h5>
                <strong style={{display:"inline-flex"}}>$<h4 style={{marginLeft:"5px"}}>{dashboard.total_Earning}</h4></strong> 
            </div>
            <a href="#"style={{fontSize:"12px",marginTop:"5px",marginLeft:"0"}}>View More</a>
          </div>
        </div>
    </div>



   
    <marquee direcction="right">Daily wishes from Beekey</marquee>
   
    <div className={styles.totaltable}>

        
    <h2 style={{marginLeft:"20px"}}>Active Bookings</h2>
{/* <div style={{background:"transparent", marginBottom:"90px"}}>
    <XYPlot height={600} width={700}>
        <LineSeries data={data} />
        <VerticalGridLines />
        <HorizontalGridLines  />
        <XAxis />
        <YAxis />
    </XYPlot>
</div> */}

 

    <Box sx={{ height: 400, width: '100%',overflow:'scroll' }}>
      <DataGrid sx={{border:'1px solid black'}}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        // disableRowSelectionOnClick
      />
    </Box>
    </div>
    
</div>;
  }
  export default DashboardSection;


