import { useSelector } from "react-redux";
import React from "react";
import styles from './UserProfile.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
const UserProfile = () => {

const UserData = useSelector(state => state.auth.user);
console.log(UserData)


const Addresses = UserData.address.map((e,index) =>{ return(
    <div className={styles.div008}>
    <div className={styles.h4002}>{"Address" + (index + 1)}</div> 
    <div className={styles.p001}>
     {e.buldingName}<br/>
     {","+ e.street + ", " + e.taluk}<br/>
     {","+ e.district + ", " + e.state +",  pin:"+ e.pin}
    </div>
    </div>
) })
return (
    <div className={styles.div000}>
        <div className={styles.div001}>
           <img src="https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg" className={styles.img001} id="img_url" alt="your image"/>
        </div>
        <div className={styles.div002}>
            <div className={styles.div006}>
               <div>
              <h3 className={styles.h3002}>{UserData['firstname'] + " "+ UserData['lastname'] }</h3>
              <h4  className={styles.h3002a}>{"Age: "+ UserData['age']} </h4>
              </div>
              <div className={styles.div005}>
              <h4  className={styles.h3002a}>{"Gender : "+UserData['gender']}</h4> 
              <h4  className={styles.h3002a}>Blood Group : <b style={{color:'red'}}>{UserData['bloodgrp']}</b></h4> 
              </div>
            </div>
            <div className={styles.div0002}>
                <h4 className={styles.h3001}>Contact Information</h4>
                <div className={styles.div004}>
                <div className={styles.div003}><FontAwesomeIcon className={styles.icon001} icon= {solid("envelope")}  color='black' size="2x"/></div>
                <div className={styles.div005}>
                <h4 className={styles.h4001}>Email Address</h4>
                <h5 className={styles.h5001}>{UserData['email']}</h5>
               </div>
                </div>
                <div className={styles.div004}>
                <div className={styles.div003}><FontAwesomeIcon className={styles.icon001} icon= {solid("phone")}  color='black' size="2x"/></div>
               <div className={styles.div005}>
                <h4 className={styles.h4001}>Phone</h4>
                <h5 className={styles.h5001}>{UserData['phone']}</h5>
               </div>
                </div>
                <div className={styles.div007}>
                <div className={styles.div004}>
                 <div className={styles.div003}><FontAwesomeIcon className={styles.icon001} icon= {solid("house")}  color='white' size="2x"/></div>
                 <div className={styles.div005}>
                  <h4 className={styles.h4001}>Address</h4><hr style={{color:'red',width:"100px"}}/>
                 </div>
                </div>
                {Addresses}
                </div>
            </div> 
        </div>
    </div>
)
};

export default UserProfile;