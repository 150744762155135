
import styles from './Input.module.css';
import { useState } from 'react';
import React from 'react';

const Input = (props) => {
    const [status,setStatus] = useState('success');
    if(props.status !== status){
        setStatus(props.status)
    };
    const onChangeHandler = (event) => {
        props.onChange(event)
    }

    return(  
     <div className={ status === 'success' ? `${styles.inputcontainer} ${props.className}` : `${styles.inputfail} ${props.className} `}>
        <input type={props.type} value={props.value} onChange={onChangeHandler}/>
        <label className ={props.value === '' ? styles.init : styles.enterd}>{props.label}</label>
     </div>)
};

export default Input;
