
import * as React from 'react';
import { useState } from "react";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';
import styles from './Dashboard.module.css';
import usericon from'../../Assets/user-icon.png';
import store from'../../Assets/store.png';
import { width } from '@mui/system';
import DashboardSection from './DashboardSection';
import ActivebookingSection from './ActivebookingSection';
import TotalbookingSection from './TotalbookingSection';
import PersonalSection from './PersonalSection';
import EarningSection from './EarningSection';
import BeekeyImage from '../../Assets/bm-icon-3.png';
import dashboard from '../../Assets/dashboard.png';
import activebookings from '../../Assets/activebookings.png';
import totalbookings from '../../Assets/totalbookings.png';
import earnings from '../../Assets/earnings.png';
import logout from '../../Assets/logout.png';
import AuthDoctor from './AuthDoctor';
import { GetApI } from '../ApiModules/ApiInterFace';
import { useDispatch } from 'react-redux';
import { AuthAction } from '../../Store/Store';
import { useSelector } from "react-redux";
import UserStatus from './Dropdown';


const drawerWidth = 240;

function LabPortal(props) {
  const { window } = props;
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const earningsData = [
    { date: '2022-01-01', amount: 500, source: 'Freelance project' },
    { date: '2022-02-01', amount: 700, source: 'Part-time job' },
    { date: '2022-03-01', amount: 900, source: 'Investment dividends' },
    { date: '2022-04-01', amount: 600, source: 'Online course sales' },
    { date: '2022-05-01', amount: 800, source: 'Freelance project' },
    { date: '2022-06-01', amount: 1000, source: 'Part-time job' },
    { date: '2022-07-01', amount: 1200, source: 'Investment dividends' },
    { date: '2022-08-01', amount: 900, source: 'Online course sales' },
    { date: '2022-09-01', amount: 1100, source: 'Freelance project' },
    { date: '2022-10-01', amount: 1300, source: 'Part-time job' },
    { date: '2022-11-01', amount: 1500, source: 'Investment dividends' },
    { date: '2022-12-01', amount: 1200, source: 'Online course sales' },
  ];
 
  const [showLogout, setShowLogout] = useState(false);

  const onLogOutOutput = (Response) => {
       //setLoading(false);
       localStorage.removeItem("beekey-doctor");
       dispatch(AuthAction.logout())
    }

  const logoutfun = async () => {
    console.log("calledlogin")
    //setLoading(true)
    GetApI({path:"/logout/doctor"
             ,callbackfunc:onLogOutOutput
           });  
}

  function toggleLogout() {
    console.log("logout")
    setShowLogout(!showLogout);
  }
  

  const [activeSection, setActiveSection] = useState("section1");
  function handleSectionClick(section) {
    setActiveSection(section);
  }


  

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
 

      <div className={styles.logo}>
        <img src={BeekeyImage} alt=""/>
        </div>
        
      <div className={styles.profile}>
        <img src={store}  alt=""/>
        
        <div className={styles.text}>
            <h1>Lab X</h1>
            <p>Pmna</p>
        </div>
      </div>
     
      <List>
          <ListItem className={styles.listitem}>   <a onClick={() => handleSectionClick("section1")} className={activeSection === "section1" ? "active" : ""}><img src={dashboard} alt=""/><div>Dashboard</div></a></ListItem> 
          <ListItem className={styles.listitem}>  <a onClick={() => handleSectionClick("section2")}> <img src={activebookings} alt=""/><div>Active Booking</div></a></ListItem> 
          <ListItem className={styles.listitem}>  <a onClick={() => handleSectionClick("section3")}> <img src={totalbookings} alt=""/><div>Bookings</div></a></ListItem> 
          <ListItem className={styles.listitem}><a onClick={() => handleSectionClick("section4")}>   <img src={earnings} alt=""/><div>Earnings</div> </a></ListItem>
          <ListItem className={styles.listitem}>   <a onClick={() => handleSectionClick("section5")}> <img src={store} alt=""/> <div>Store</div></a></ListItem> 
          <ListItem className={styles.listitem}>    <a ><img src={logout} alt=""/> <div><div onClick={logoutfun}>Logout</div></div></a></ListItem> 
              
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
 const authState = useSelector(state => state.auth);

  //

  return (
    <AuthDoctor>
      
    <Box sx={{ display: 'flex',background:"transparent",padding:"0",maxWidth:"100vw" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar className={styles.header}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" style={{width:"fit-content",background:"transaprent",margin:"0px"}}>
            Mr. Doctor
          </Typography>

          <div  className={styles.procont}>
          <div className={styles.profileicon}>
            {authState.isAuthenticated && (
             <div style={{background:"#92e3a9", position: "relative",height:"auto",top:"auto",display:"flex",flexDirection:"column",alignItems:"flex-start",padding:"0",margin:"0px",borderRadius:"20px",transition:"all in 0.5s ease"}} >
                <UserStatus/>
             </div>
                
            
            )}
          </div>

            
            <div className={styles.profileicon} onClick={toggleLogout}>
            {showLogout && (

                     <div className={styles.div001}> 
                      <img src={logout} alt="" style={{width: "25px"}}/>
                      <div  onClick={logoutfun}>
                      Logout
                      </div>
                    </div>
                )}
                <img src={usericon} alt="User Avatar" />
                
            </div>

          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        className={styles.maincontiner}
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar  />
    
        


            <div>
                {activeSection === "section1" && <DashboardSection />}
                {activeSection === "section2" && <ActivebookingSection />}
                {activeSection === "section3" && <TotalbookingSection />}
                {activeSection === "section4" && <EarningSection earningsData={earningsData} />}
                {activeSection === "section5" && <PersonalSection />}
            </div>
            
        
      </Box>
    </Box>
    </AuthDoctor>
  );
}

LabPortal.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};




  

export default LabPortal;