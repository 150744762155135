import styles from './fullOrderData.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState } from 'react';
import React from 'react';

const  FullOrderData = (props) => {
    console.log("$$$$$$$$$$$$$$$$$",props.completOrderData)
    const data = props.completOrderData;
    console.log("4356476",data["status"])
    const [age, setAge] = useState(data["status"]);
    if(data["status"] !== age){
      setAge(data["status"])
    }

    const handleChange = (event) => {
      props.changeOrderStatus({...data,status :event.target.value })
      setAge(event.target.value);
    };
  
    var timestamp = data["a_ctime"];  
    var date = new Date(timestamp);
    const Time = date.getDate() + " " + date.toLocaleString('en-us', { month: 'short' }) + date.getFullYear() +" - " +
    date.toLocaleString('en-US', { hour: 'numeric', minute:'numeric', hour12: true })

    return(
        <div>
        <div className={styles.div0023}>
              <div>
                <h2 className={styles.h20001}>{data["number"]}</h2>
                <p className={styles.para0001}>{data["uuid"]}</p>
                <div className={styles.div0022}>
                  <p className={styles.para0002}>{Time}</p>
                  {/* <p className={styles.para0002}>{JSON.stringify(data["medicalshop"]["address"][0])}</p> */}
                  <p className={styles.para0002}> westyakkara</p> 
                </div>
              </div>
                <FormControl sx={{ m: 1, minWidth: 120 }} className={styles.div0024}>
                       <Select value={age}  onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
                       <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="accepted">Accepted</MenuItem>
                        <MenuItem value="ready_for_pickup">Ready For PickUp</MenuItem>
                        <MenuItem value="deliverd">Deliverd</MenuItem>
                        <MenuItem value="delivery_started">Out for Delivery</MenuItem>
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                  </Select>
              </FormControl>
            </div>
            <div  className={styles.div0026}>
              <div className={styles.div0025}>
              <h5  className={styles.h50001}>OUTLET  </h5>            
              <p className={styles.para0003}>Taste of India - Bur Dubai</p>
              </div>
              <div  className={styles.div0025}>
                <h5  className={styles.h50001}>CUSTOMER NAME</h5>
                <p className={styles.para0003}><p>{data["user"]["firstname"]}  {data["user"]["lastname"]}</p></p>
              </div>
              <div  className={styles.div0025}>
                <h5  className={styles.h50001}>MOBILE NUMBER</h5>
                <p className={styles.para0003}>+{data["user"]["phone"]}</p>
              </div>
              <div  className={styles.div0025}>
                <h5  className={styles.h50001}>DELIVERY ADDRESS</h5>
                {/* <p className={styles.para0003}>{JSON.stringify(data["user"]["address"][0])}</p> */}
                <p className={styles.para0003}>yakkaramurrikavu</p>
              </div>
              <div   className={styles.div0025}>
                <h5 className={styles.h50001}>PAYMENT</h5>
                <p className={styles.para0003}>{data["payement_type"]}</p>
              </div>
            </div>
            <div>
              <div className={styles.div0027}>
                 <h3 className={styles.h30001}>Delivery Agent Info</h3>
                 <div className={styles.div0028}>
                 <FontAwesomeIcon icon={solid("user-check")} size="1x" className={styles.icon}  />
                 Deliverd</div>
               </div>
               <div className={styles.div0029}>
                 <div  className={styles.div0025}>
                  <h4 className={styles.h40001}> DA NAME</h4>
                  <p className={styles.para0003}>{data["delivery"]["firstname"] ?data["delivery"]["firstname"]:"sanad"} 
                  {data["delivery"]["lastname"] ? data["delivery"]["lastname"]: "ca"} </p>
                 </div>
                 <div  className={styles.div0025}>
                  <h4 className={styles.h40001}>DA PHONE NO</h4>
                  <p className={styles.para0003}>+{data["delivery"]["phone"]?data["delivery"]["phone"]:"1234567890"}</p>
                 </div>
              </div>
            </div>
            <div className={styles.div0029a}>
              prescription
            </div>
            <div className={styles.div0030}>
               <div className={styles.div0031}>
                 <h3 className={styles.h30002}>Sub Total</h3>
                 <h3  className={styles.h30002}>Rs {data['amount']}</h3>
               </div>
               <div className={styles.div0031}>
                  <h3  className={styles.h30002}>Discount - (Restaurant)</h3>
                 <h3  className={styles.h30002}>Rs {data['discount']}</h3>
               </div>
               <div className={styles.div0031}>
                 <h3  className={styles.h30002}>Order Total</h3>
                 <h3  className={styles.h30002}>Rs {data['final_amount']}</h3>
               </div>
            </div>
            </div>
    )
};

export default FullOrderData;