// import styles from './Packages.module.css';
// import { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
// import img7 from '../../../Assets/package1.jpg';
// import img8 from '../../../Assets/package2.jpg';
// import img9 from '../../../Assets/package3.jpg';
// import img10 from '../../../Assets/emptycart.jpg';
// import "react-multi-carousel/lib/styles.css";
// import React from 'react';

// const Packages = () => {

// const [showPackage,setShowPackage] = useState("All");

// const allPackages= [{
//     id:"001",
//     packge:"Family Doctor",
//     description:"24X7 Unlimited Online Consultation with General Mediine Doctors",
//     validity:"6",
//     offerprice:"200",
//     marketprice:"400",
//     img:img7
//  },
//  {
//  id:"002",
//  packge:"Family Clinic Pacakge",
//  description:"In-Clinic consults with experienced specialists at Beekey Medic ",
//  validity:"6",
//  offerprice:"750",
//  marketprice:"1000",
//  img:img8
//  },
//  {
//     id:"003",
//     packge:"Mental Welnes Saving Packge",
//     description:"For Best Mental Health Vist our specialist and rs.200 for first 2 visit",
//     validity:"6",
//     offerprice:"400",
//     marketprice:"500",
//     img:img9
//     }
//  ]; 

// let packages = <div></div>
// if(showPackage==="All"){
//      packages = 
//        <div  style={{marginTop:"40px"}}>
//         {allPackages.map(
//           (e) =>{
//              return(
//                <div key={e.id} style={{display:"flex"
//                                        ,flexDirection:"column"
//                                        ,borderRadius:"10px"
//                                        ,color:'rgb(92 33 115)'
//                                        ,backgroundImage:`url(${e.img})`,
//                                         backgroundRepeat:'no-repeat', 
//                                         backgroundSize:"cover",
//                                         justifyContent:'space-between'
//                                         ,boxShadow: "0 2px 4px black"
//                                         ,height:"190px"
//                                         ,margin:'20px'}}> 
//                 <div style={{marginTop:"20px",marginLeft:'10px'}}>
//                    <span className={styles.para00117}>{e.packge}</span>
//                    <p className={styles.para00127}>{e.description}</p>
//                 </div>
//                 <div style={{display:"flex",justifyContent:"space-between"}}>
//                    <div style={{dsiplay:"flex",flexDirection:"column",height:"35px",justifyContent:'space-between'}}>
//                       <p  className={styles.para009}>Validity: Starts from {e.validity} months</p>
//                       <div style={{display:"flex",flexDirection:"row",marginTop:'5px',justifyContent:'space-around'}}>
//                          <p className={styles.para008}>{e.offerprice}</p>
//                          <del className={styles.para009} >{e.marketprice}</del>
//                         <p className={styles.para009}>onwards</p>
//                       </div>
//                   </div>
//                   <div style={{backgroundColor:'#3ad63a',borderTopLeftRadius:"5px",borderBottomRightRadius:"5px",width:"110px", display:"flex",alignItems:'center' , border: '1px solid white',justifyContent:'space-around'}}>
//                    Explore
//                    <FontAwesomeIcon icon={solid("circle-chevron-right")} size='1x'/>
//                   </div>
//                 </div> 
//                 </div> 
//              )
//           }
    
//         )}
    
//        </div>
//     }else{
//        packages = 
//           <div className={styles.div0018}>
//              <img className={styles.img004} src={img10} alt="empty cart"/>
//              <h1 className={styles.h10001}> No packages to show!</h1>
//              <p className={styles.para0021}>Buy health Packages to get multiple benefits</p>
//              <button className={styles.button004} onClick={() => setShowPackage("All")}>Buy Now</button>
//           </div>
//     }   
//     return(
//     <div>
//         <div className={styles.div0016}>
//         <div className={styles.div0017} >
//         <p  className={styles.para0011} onClick={() => setShowPackage("All")} style={{color:showPackage ==='All' ? 'rgb(70, 185, 70)': 'rgb(148, 147, 147)'}}>All PACKAGES</p>
//         </div>
//         <div className={styles.div0017}>
//         <p  className={styles.para0011} onClick={() => setShowPackage("Mypackage")} style={{color:showPackage ==='Mypackage' ? 'rgb(70, 185, 70)': 'rgb(148, 147, 147)'}}>MY PACKAGES</p>
//         </div>
//       </div>
//       <span   className={styles.span0002} style={{ transform: showPackage ==='All' ? "translate3d(0px, 0px, 0px)": "translate3d(194px, 0px, 0px)",transitionDuration:'0.3s'}}></span>
//       {packages}
//      </div>
//     )
// };


// export default Packages;



// YourPage.js
import React from 'react';
import PackagePage from './Packages/PackagePage';
import styles from './Packages.module.css'

const Packages = () => {
  const userPackage = {
    name: 'Premium Package',
    description: 'The best package with all the features',
    price: 99.99,
  };

  const otherPackages = [
    { name: 'Standard Package', description: 'A good package', price: 49.99 },
    { name: 'Basic Package', description: 'Basic features included', price: 29.99 },
  ];

  return (
    <div className={styles.maindiv1}>
      <h1>Package Information</h1>
      <PackagePage userPackage={userPackage} otherPackages={otherPackages} />
    </div>
  );
};

export default Packages;

