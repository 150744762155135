import Offer from './Offer';
import styles from './OfferList.module.css';
// import BrandImg from '../../Assets/brand.png';
// import RestuarantImage from '../../Assets/Restaurant.png';
// import LogoImage from '../../Assets/logodesign.jpg';
// import WebDevImage from '../../Assets/Webdevelopement.jpg';
// import DigitalMarketImg from '../../Assets/digitalmarketing.jpeg';
//import styles from './OfferList.module.css';
import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from 'react';
import Quality from './Quality';
import QualityIcons from './Qualityicons';
import QualityOffers from './QualityOffers';
import Medcinetab from './medicineTab';

const defaultOffers = [{id:"001"
                       ,icon:"Insta Doc"
                       ,img: "laptop-code"
                       ,description:"Consult Online Now"
                       ,message: "Doctors 24/7?"                       
                    },{id:"002"
                        ,icon:"Book Appointment"
                        ,img:"envelopes-bulk"
                        ,description:"Online/Clinic"
                        ,message: "Curated Doctors"                       
                     },
                     {id:"003"
                        ,icon:"Buy Medicines"
                        ,img:"file-circle-check"
                        ,description:"Trusted Partners"
                        ,message: "Doorstep delivery"                       
                     },
                     {id:"004"
                        ,icon:"Book Lab Tests"
                        ,img:"paintbrush"
                        ,description:"NABL Certified partners"
                        ,message: "Safe and Hygenic?"                       
                     },
                     {id:"005"
                     ,icon:"Family Doctor"
                     ,img:"For you and Family"
                     ,description:"Trust your doctor"
                     ,message: "Unlimited Consults?"                       
                  }];
const defaultQuality = [
   {id:"001",quality:"Trusted Medicines",description:"Fast and reliable medicine delivery to your door."}
   ,{id:"002",quality:"Experienced Doctors",description:"Consult with top-rated doctors from home."}
   ,{id:"003",quality:"Best of Labs in the region",description:"Accurate lab test results, easily accessible."}]


   const initState = [{icon:"Insta Doc",status:true}
   ,{icon:"Book Appointment",status:false}
   ,{icon:"Buy Medicines",status:false}
   ,{icon:"Book Lab Tests",status:false}
   ,{icon:"Family Doctor",status:false}
 ];


 const medicinetab = [{id:"001"
 ,icon:"First Aid Box"
 ,img: "laptop-code"
 ,description:"Make sure it's in there"
 ,message: "Order Now"                       
},{id:"002"
  ,icon:"Book Appointment"
  ,img:"envelopes-bulk"
  ,description:"Online/Clinic"
  ,message: "Order Now"                       
},
{id:"003"
  ,icon:"Buy Medicines"
  ,img:"file-circle-check"
  ,description:"Trusted Partners"
  ,message: "Order Now"                       
},
{id:"004"
  ,icon:"Book Lab Tests"
  ,img:"paintbrush"
  ,description:"NABL Certified partners"
  ,message: "Order Now"                       
},
{id:"005"
,icon:"Family Doctor"
,img:"For you and Family"
,description:"Trust your doctor"
,message: "Order Now"                       
}];

 const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1000 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1000, min: 464 },
    items: 3  
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2
  },

  smallmobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1
  }

}

const OfferList = () => {


    
   const [state, setState] = useState(initState);
  
   let ClickedIconIndex = 0;

   const onClickHandler = (event) => {
      setState((prev) => { 
      const S = [...prev];
      S.forEach((obj,I) => {if(obj.icon === event)
                           {S[I].status = true;
                            console.log("gchg@@",I)
                            ClickedIconIndex = I;  
                            console.log(ClickedIconIndex,I, "****8")
                           }
                        else{S[I].status = false}});
      return(S)})
 };
 ClickedIconIndex = state.findIndex((e)=> {return(e.status)})


 console.log("applying",ClickedIconIndex)
  return (

    <div className={styles.maindiv1}>
   <div className={styles.maindiv2}>             
    {defaultOffers.map((e) => 
    <div><Offer key={e.id} 
           id={e.id}
           icon={e.icon}
           description={e.description}
           message={e.message}
           size="100%"
            />
         </div>)}
    </div>
    
    <div style={{background:"aliceblue"}}>
      <div className={styles.beekeyletter} style={{fontSize:"28px",marginBottom:"0px"}}>Why Beekey Medics?</div>       
    <div className={styles.qualityicon}>
      {defaultQuality.map((e) =>
       <Quality key= {e.id}
       id={e.id}
       quality={e.quality}
       description={e.description}
       />)}
      </div>
      {/* <div className={styles.letterstyle000}>
          <div className={styles.letterstyle001}>Experience Us</div>
          <div className={styles.letterstyle002}>Comprehensive. Convenient. Caring.</div>
       <Carousel responsive={responsive} containerClass={styles.containerClass} removeArrowOnDeviceType={["tablet", "mobile"]} swipeable={true}>
        {defaultOffers.map((e) => <QualityIcons key= {e.id} id={e.id} name={e.icon} state={state} onClick={onClickHandler}/>)}
       </Carousel>
       </div>
       <div>
       <QualityOffers index={ClickedIconIndex}/>
       </div> */}
      </div>  
      <div className={styles.maindiv20}>  
      <div className={styles.beekeyletter} style={{fontSize:"28px",marginBottom:"30px"}}>General Medicines</div>
      <div className={styles.maindiv2}>             
    {medicinetab.map((e) => 
    <div><Medcinetab key={e.id} 
           id={e.id}_
           icon={e.icon}
           description={e.description}
           message={e.message}
           size="100%"
            />
         </div>)}
    </div>
    </div>

    </div>
            )
        };

export default OfferList;






