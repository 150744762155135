import React from "react";
import BeekeyGenaralInfo from "./BeekeyGenaralInfo";
import BeekeyInfo from "./BeekeyInfo";
import Contact from "./Contact";


const Footer = () => {

return(
    <div>
        <Contact/>
        <BeekeyInfo/>
        <BeekeyGenaralInfo/>

    </div>

)

};
export default Footer;