import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import { useState} from 'react';
import styles from './QuestionsAnswer.module.css';
import React from 'react';

const QuestionsAnswers = (props) => {
    const display = props.questionsAnswer.map((e) =>{return false});
    console.log(display)

    const [state ,setState] = useState(display);

    const onClickHandler = (ind1) => {
        setState(prevState => {
            return(prevState.map((e,ind2) =>{      
            if (ind1 === ind2){return !e}
            else{ return false}           
        }))})


    };

 return(
  <ul >
    {props.questionsAnswer.map((e,index) => {
        console.log(state[index],"0000")
        return(
    <div className={styles.content001} key={index}>
        <div className={styles.content002}>
            <p className={styles.paraQuest}>{e.question}</p>
            <FontAwesomeIcon icon={solid("angle-right")}  onClick ={event => onClickHandler(index)} className={state[index]  ? styles.rotate90: ""} color="rgb(95, 93, 93)"/>
        </div>
        <p style ={{display: state[index] ? 'block':'none'}}   className={styles.paraAnswer}>{e.answer}</p>
    </div>)})}
  </ul>);
  
};

export default QuestionsAnswers;


