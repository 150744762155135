import React,{useState} from 'react';
import styles from './SlotsModal.css'
import Input from '../UI/Input';
import Select from '../UI/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import RazorpayButton from './RazorpayButton';
import doc_bookedImg from '../../Assets/doc_booked.png'


const SlotsModal = ({ onClose ,slot, submit, proceed}) => {
  console.log("inside modal",proceed,slot.id,);
  const initValue  = {
    id:slot.id,
    firstname: '',
    lastname: '',
    phone: '',
    email:'',
    age:'',
    self:true,
    gender:'',
    issue:''
  };
 const [values, setValues] = useState(initValue);

 const handleChange =  (event,Ind) => {
  setValues({ ...values, [Ind]: event.target.value });
};

const handleChange1 =  (event) => {
  setValues({ ...values, self: event.target.checked});
};

if(Object.keys(slot.resource).length == 0 ){
  return (
    <div>
    <div className="modal-container"> </div>
      { proceed === null ? <div className='modal text-left' > 
         <h2 className='mb-2 text-left'>Patient Info</h2>   
         <div className="d-flex justify-content-end align-items-center w-100 mt-10">
           <label>Self</label>  
         <Checkbox defaultChecked  onChange={(e) => {handleChange1(e)}}/>  
          </div>
          {values.self &&
          <div>
          <div className="d-flex flex-row justify-content-between w-100 mt-10">
         <Input status='success' type="text" value={values.firstname} label='firstname*' onChange={(e) => {handleChange(e,'firstname')}}></Input>
         <Input status='success' type="text" value={values.lastname} label='lastname*' onChange={(e) => handleChange(e,'lastname')}></Input>    
         </div>
         <div className="d-flex flex-row justify-content-between  w-100 mt-10">
         <Input  status='success' type="email" value={values.email} label='Email*' onChange={(e) => handleChange(e,'email')}></Input>
         <Input  status='success' type="phone"  value={values.phone} label='Phone*' onChange={(e) => handleChange(e,'phone')}></Input>
         </div>
         <div className="d-flex flex-row justify-content-between  w-100 mt-10">
         <Input  status='success' type="number" className ={styles.input0003} value={values.age} label='Age*' onChange={(e) => handleChange(e,'age')}></Input>
         </div>
         </div>}
         <Input  status='success' type="issue" className ={styles.input0003} value={values.issue} label='Issue' onChange={(e) => handleChange(e,'issue')}></Input>
         <hr   style={{color: "black", backgroundColor: "black",height: 1, width:"100%"}}/>
         <div className='w-100 d-flex flex-row justify-content-start mt-2'>
          <div className="d-flex flex-row justify-content-between w-50">
         <Button variant="outlined" onClick={onClose}>Cancel</Button>
         <Button variant="contained" onClick={() => {submit(values)}}>Continue</Button>
         </div>
         </div>
       </div>: 
       <div className='modal text-left'> 
       <RazorpayButton proceed = {proceed}/>
       </div>}
    </div>
   )}else{

    return(
      <div className="modal-container" >
      <div className="modal">
        {/* Modal content for empty object */}
        <div className="modal-content2">
          <img className='w-75 p-1 ' src={doc_bookedImg}/>
          <p>Alreardy booked</p>
          <div className='d-flex justify-content-around'>
          <button onClick={onClose} className="btn btn-light">Ok</button>
          </div>
        </div>
      </div>
      </div>
    )
   }
};

export default SlotsModal;





// // SlotsModal.js
// import React, { useState } from 'react';
// import './SlotsModal.css'; // Import a CSS file for styling
// import moment from 'moment';

// const SlotsModal = ({ selectedDate, availableSlots, onSave, onClose, onModalSave }) => {
//   const [selectedSlots, setSelectedSlots] = useState([]);

//   const handleSlotToggle = (slotIndex) => {
//     setSelectedSlots((prevSlots) =>
//       prevSlots.includes(slotIndex)
//         ? prevSlots.filter((index) => index !== slotIndex)
//         : [...prevSlots, slotIndex]
//     );
//   };

//   const handleSave = () => {
//     onSave(selectedSlots);
//     onClose(); // Close the modal after saving
//     if (onModalSave) {
//       onModalSave(selectedSlots);
//     }
//   };

//     // Remove the resize event listener when the component is unmounted
//     React.useEffect(() => {
//       return () => {
//         window.removeEventListener('resize', handleResize);
//       };
//     }, []);

//   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

//   const handleResize = () => {
//     setViewportWidth(window.innerWidth);
//   };

//   // Attach the resize event listener
//   window.addEventListener('resize', handleResize);


//   return (
//     <div className={`modal-container ${viewportWidth <= 600 ? 'small-screen' : ''}`}>
//       <div className="modal-content">
//         <h3>Manage Available Slots - {selectedDate && selectedDate.toDateString()}</h3>

        
//         <div className="modal-buttons">
//           <button onClick={handleSave}>Save</button>
//           <button onClick={onClose}>Close</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const renderSlotRows = (selectedDate, availableSlots, selectedSlots, handleSlotToggle) => {
//   const rows = [];
//   const slotsPerRow = 8; // Adjust the number of slots per row as needed

//   for (let i = 0; i < availableSlots.length; i += slotsPerRow) {
//     const rowSlots = availableSlots.slice(i, i + slotsPerRow);
//     rows.push(
//       <tr key={i}>
//         {rowSlots.map((slotIndex) => (
//           <td key={slotIndex}>
//             <label>
//               <input
//                 type="checkbox"
//                 checked={selectedSlots.includes(slotIndex)}
//                 onChange={() => handleSlotToggle(slotIndex)}
//               />
//               <span className="slot-time">{renderSlotTime(selectedDate, slotIndex)}</span>
//             </label>
//           </td>
//         ))}
//       </tr>
//     );
//   }

//   return rows;
// };

// const renderSlotTime = (selectedDate, slotIndex) => {
//   const startTime = moment(selectedDate).startOf('day').add(slotIndex * 15, 'minutes');
//   const endTime = moment(startTime).add(15, 'minutes');

//   return `${startTime.format('LT')} - ${endTime.format('LT')}`;
// };

// export default SlotsModal;
